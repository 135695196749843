import { ISessionProps } from "../main/components/IProgress";

const isProd = true;
const prodEndpoint =
  "ghyc5vmdyyed6i3addfrueoqem0nsgws.lambda-url.eu-west-1.on.aws";
const stagingEndpoint =
  "6njpg6xs6zcvbkgmj4hftokkai0rgvra.lambda-url.eu-west-1.on.aws";
const api_endpoint = isProd ? prodEndpoint : stagingEndpoint;

export function updateProgressState(newState: ISessionProps) {
  // not sending state to the server without AppID
  if (!newState.appID) return;

  // console.log("newState", newState);

  const url = `https://${api_endpoint}/updateProgress`;
  const options = {
    method: "POST",
    body: JSON.stringify(newState),
    headers: {
      "Content-Type": "application/json",
    },
  };

  fetch(url, options)
    .then((res) => res.json())
    .then((res) => console.log("updateState", res))
    .catch((err) => console.error(err));
}

export interface Feedback {
  usefulValue: string;
  easyValue: string;
  feedbackValue: string;
  emailValue: string;
}

export function sendFeedback(feedbackForm: Feedback, newState: ISessionProps) {
  // not sending state to the server without AppID

  const url = `https://${api_endpoint}/sendFeedback`;
  const options = {
    method: "POST",
    body: JSON.stringify({
      state: newState,
      feedback: feedbackForm,
      feedbackTime: new Date(),
    }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  fetch(url, options)
    .then((res) => res.json())
    .then((res) => console.log("sendFeedback", res))
    .catch((err) => console.error(err));
}

export function getGCD(device_id: string, devkey?: string, appID?: string) {
  if (!devkey || !appID) {
    const lsAppId = localStorage.getItem("appId");
    if (lsAppId) {
      appID = JSON.parse(lsAppId);
    } else return null;

    const lsDevKey = localStorage.getItem("devKey");
    if (lsDevKey) {
      devkey = JSON.parse(lsDevKey);
    } else return null;
  }

  const url = `https://${api_endpoint}/getGCD`;
  const options = {
    method: "POST",
    body: JSON.stringify({
      appID,
      devkey,
      device_id,
    }),
    ccredentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options).then((res) => res.json());
}

export function testConsoleRegister(
  appID: string,
  device_id: string,
  device_id_type: string,
  device_name: string,
  token: string
) {
  const url = `https://${api_endpoint}/testconsole/register`;

  const options = {
    method: "POST",
    body: JSON.stringify({
      appID,
      device_id,
      device_id_type,
      device_name,
      token,
    }),
    ccredentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options).then((res) => res.json());
}

export function testConsoleEvents(
  appID: string,
  device_id: string,
  token: string
) {
  const url = `https://${api_endpoint}/testconsole/events`;
  const date = new Date();
  date.setMinutes(date.getMinutes() - 5);
  const start_time = date.toISOString();
  const options = {
    method: "POST",
    body: JSON.stringify({
      appID,
      device_id,
      token,
      start_time,
    }),
    ccredentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options).then((res) => res.json());
}

export function testConsoleOnelinkSig(
  devKey: string,
  oneLinkId: string,
  shortLinkId: string
) {
  const url = `https://${api_endpoint}/testconsole/onelinksig`;
  const options = {
    method: "POST",
    body: JSON.stringify({
      devKey,
      oneLinkId,
      shortLinkId,
    }),
    ccredentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options).then((res) => res.json());
}

export function getApiVer() {
  const url = `https://${api_endpoint}/getApiVer`;
  const options = {
    method: "GET",
    ccredentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options).then((res) => res.json());
}
