//   //Events:
  
//   //EventNameArray:
//   exports.iosEventName = [
//     "af_achievement_unlocked",
//     "af_ad_click",
//     "af_ad_view",
//     "af_add_payment_info",
//     "af_add_to_cart",
//     "af_add_to_wishlist",
//     "af_complete_registration",
//     "af_content_view",
//     "af_customer_segment",
//     "af_initiated_checkout",
//     "af_invite",
//     "af_level_achieved",
//     "af_list_view",
//     "af_location_coordinates",
//     "af_login",
//     "af_opened_from_push_notification",
//     "af_purchase",
//     "af_rate",
//     "af_re_engage",
//     "af_search",
//     "af_share",
//     "af_spent_credits",
//     "af_start_trial",
//     "af_subscribe",
//     "af_travel_booking",
//     "af_tutorial_completion",
//     "af_update",
//   ];
//   //IosEventConstantNameArray:
//    exports.iosEventConstantName = [
//     "AFEventAchievementUnlocked",
//     "AFEventAdClick",
//     "AFEventAdView",
//     "AFEventAddPaymentInfo",
//     "AFEventAddToCart",
//     "AFEventAddToWishlist",
//     "AFEventCompleteRegistration",
//     "AFEventContentView",
//     "AFEventCustomerSegment",
//     "AFEventInitiatedCheckout",
//     "AFEventInvite",
//     "AFEventLevelAchieved",
//     "AFEventListView",
//     "AFEventLocation",
//     "AFEventLogin",
//     "AFEventOpenedFromPushNotification",
//     "AFEventPurchase",
//     "AFEventRate",
//     "AFEventReEngage",
//     "AFEventSearch",
//     "AFEventShare",
//     "AFEventSpentCredits",
//     "AFEventStartTrial",
//     "AFEventSubscribe",
//     "AFEventTravelBooking",
//     "AFEventTutorial_completion",
//     "AFEventUpdate",
//   ];
//   exports.iosTypeEvents = exports.iosEventName.map((name, index) => ({
//     name,
//     constant: exports.iosEventConstantName[index],
//   }));




//   //Parameters:

//   //EventParameterNameArray
//   exports.iosEventParameterName = [
//     "af_achievement_id",
//     "af_adrev_ad_size",
//     "af_adrev_ad_type",
//     "af_adrev_mediated_network_name",
//     "af_adrev_network_name",
//     "af_class",
//     "af_content",
//     "af_content_id",
//     "af_content_list",
//     "af_content_type",
//     "af_coupon_code",
//     "af_currency",
//     "af_customer_user_id",
//     "af_date_a",
//     "af_date_b",
//     "af_deep_link",
//     "af_departing_arrival_date",
//     "af_departing_departure_date",
//     "af_description",
//     "af_destination_a",
//     "af_destination_b",
//     "af_destination_list",
//     "af_event_end",
//     "af_event_start",
//     "af_hotel_score",
//     "af_level",
//     "af_long",
//     "af_max_rating_value",
//     "af_new_version",
//     "af_num_adults",
//     "af_num_children",
//     "af_num_infants",
//     "af_old_version",
//     "af_order_id",
//     "af_param_1",
//     "af_param_10",
//     "af_param_2",
//     "af_param_3",
//     "af_param_4",
//     "af_param_5",
//     "af_param_6",
//     "af_param_7",
//     "af_param_8",
//     "af_param_9",
//     "af_payment_info_available",
//     "af_preferred_neighborhoods",
//     "af_preferred_num_stops",
//     "af_price",
//     "af_projected_revenue",
//     "af_purchase_currency",
//     "af_quantity",
//     "af_rating_value",
//     "af_receipt_id",
//     "af_region",
//     "af_revenue",
//     "af_review_text",
//     "af_returning_arrival_date",
//     "af_returning_departure_date",
//     "af_score",
//     "af_search_string",
//     "af_suggested_destinations",
//     "af_suggested_hotels",
//     "af_success",
//     "af_tutorial_id",
//     "af_user_score",
//     "af_validated",
//     "af_virtual_currency_name",
//     "af_travel_end",
//     "af_travel_start",
//     "af_lat",
//     "af_long",
//     "af_city",
//     "af_country",
//   "af_preferred_price_range",
// "af_preferred_star_ratings"  
//   ];
//   //IosParameterConstantNameArray
//   exports.iosParameterConstantName=[ "AFEventParamContent",
//  "AFEventParamAchievementId",
//  "AFEventParamLevel",
//  "AFEventParamScore",
//  "AFEventParamSuccess",
//  "AFEventParamPrice",
//  "AFEventParamContentType",
//  "AFEventParamContentId",
//  "AFEventParamContentList",
//  "AFEventParamCurrency",
//  "AFEventParamQuantity",
//  "AFEventParamRegistrationMethod",
//  "AFEventParamPaymentInfoAvailable",
//  "AFEventParamMaxRatingValue",
//  "AFEventParamRatingValue",
//  "AFEventParamSearchString",
//  "AFEventParamDateA",
//  "AFEventParamDateB",
//  "AFEventParamDestinationA",
//  "AFEventParamDestinationB",
//  "AFEventParamDescription",
//  "AFEventParamClass",
//  "AFEventParamEventStart",
//  "AFEventParamEventEnd",
//  "AFEventParamLat",
//  "AFEventParamLong",
//  "AFEventParamCustomerUserId",
//  "AFEventParamValidated",
//  "AFEventParamRevenue",
//  "AFEventProjectedParamRevenue",
//  "AFEventParamReceiptId",
//  "AFEventParamTutorialId",
//  "AFEventParamVirtualCurrencyName",
//  "AFEventParamDeepLink",
//  "AFEventParamOldVersion",
//  "AFEventParamNewVersion",
//  "AFEventParamReviewText",
//  "AFEventParamCouponCode",
//  "AFEventParamOrderId",
//  "AFEventParam1",
//  "AFEventParam2",
//  "AFEventParam3",
//  "AFEventParam4",
//  "AFEventParam5",
//  "AFEventParam6",
//  "AFEventParam7",
//  "AFEventParam8",
//  "AFEventParam9",
//  "AFEventParam10",
//  "AFEventParamDepartingDepartureDate",
//  "AFEventParamReturningDepartureDate",
//  "AFEventParamDestinationList",
//  "AFEventParamCity",
//  "AFEventParamRegion",
//  "AFEventParamCountry",
//  "AFEventParamDepartingArrivalDate",
//  "AFEventParamReturningArrivalDate",
//  "AFEventParamSuggestedDestinations",
//  "AFEventParamTravelStart",
//  "AFEventParamTravelEnd",
//  "AFEventParamNumAdults",
//  "AFEventParamNumChildren",
//  "AFEventParamNumInfants",
//  "AFEventParamSuggestedHotels",
//  "AFEventParamUserScore",
//  "AFEventParamHotelScore",
//  "AFEventParamPurchaseCurrency",
//  "AFEventParamPreferredNeighborhoods",
//  "AFEventParamPreferredNumStops",
//  "AFEventParamAdRevenueAdType",
//  "AFEventParamAdRevenueNetworkName",
//  "AFEventParamAdRevenuePlacementId",
//  "AFEventParamAdRevenueAdSize",
//  "AFEventParamAdRevenueMediatedNetworkName",
//  "AFEventParamPreferredPriceRange",
//  "AFEventParamPreferredStarRatings",

//  ];




// exports.typesArray = [
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "float",
//     "String",
//     "String",
//     "String[]",
//     "String",
//     "int",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "boolean",
//     "float",
//     "float",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String[]",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String[]",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String[]",
//     "String",
//     "String",
//     "String",
//     "String[]",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "String",
//     "int[]",
//     "int[]",
//   ];

//   exports.iosParameter = exports.iosEventParameterName.map((name, index) => ({
//     name,
//     constant: exports.iosParameterConstantName[index],
//     type:exports.typesArray[index],
//   }));

exports.iosParameter=[
  {
    "event_parameter_name": "af_content",
    "ios_constant_name": "AFEventParamContent",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_achievement_id",
    "ios_constant_name": "AFEventParamAchievementId",
    "Type": "String"
    },
    {
      "event_parameter_name": "af_level",
      "ios_constant_name": "AFEventParamLevel",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_score",
      "ios_constant_name": "AFEventParamScore",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_success",
      "ios_constant_name": "AFEventParamSuccess",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_price",
      "ios_constant_name": "AFEventParamPrice",
      "Type": "float"
    },
    {
      "event_parameter_name": "af_content_type",
      "ios_constant_name": "AFEventParamContentType",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_content_id",
      "ios_constant_name": "AFEventParamContentId",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_content_list",
      "ios_constant_name": "AFEventParamContentList",
      "Type": "String[]"
    },
    {
      "event_parameter_name": "af_currency",
      "ios_constant_name": "AFEventParamCurrency",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_quantity",
      "ios_constant_name": "AFEventParamQuantity",
      "Type": "int"
    },
    {
      "event_parameter_name": "af_registration_method",
      "ios_constant_name": "AFEventParamRegistrationMethod",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_payment_info_available",
      "ios_constant_name": "AFEventParamPaymentInfoAvailable",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_max_rating_value",
      "ios_constant_name": "AFEventParamMaxRatingValue",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_rating_value",
      "ios_constant_name": "AFEventParamRatingValue",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_search_string",
      "ios_constant_name": "AFEventParamSearchString",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_date_a",
      "ios_constant_name": "AFEventParamDateA",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_date_b",
      "ios_constant_name": "AFEventParamDateB",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_destination_a",
      "ios_constant_name": "AFEventParamDestinationA",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_destination_b",
      "ios_constant_name": "AFEventParamDestinationB",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_description",
      "ios_constant_name": "AFEventParamDescription",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_class",
      "ios_constant_name": "AFEventParamClass",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_event_start",
      "ios_constant_name": "AFEventParamEventStart",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_event_end",
      "ios_constant_name": "AFEventParamEventEnd",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_lat",
      "ios_constant_name": "AFEventParamLat",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_long",
      "ios_constant_name": "AFEventParamLong",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_customer_user_id",
      "ios_constant_name": "AFEventParamCustomerUserId",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_validated",
      "ios_constant_name": "AFEventParamValidated",
      "Type": "boolean"
    },
    {
      "event_parameter_name": "af_revenue",
      "ios_constant_name": "AFEventParamRevenue",
      "Type": "float"
    },
    {
      "event_parameter_name": "af_projected_revenue",
      "ios_constant_name": "AFEventProjectedParamRevenue",
      "Type": "float"
    },
    {
      "event_parameter_name": "af_receipt_id",
      "ios_constant_name": "AFEventParamReceiptId",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_tutorial_id",
      "ios_constant_name": "AFEventParamTutorialId",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_virtual_currency_name",
      "ios_constant_name": "AFEventParamVirtualCurrencyName",
      "Type": ""
    },
    {
      "event_parameter_name": "af_deep_link",
      "ios_constant_name": "AFEventParamDeepLink",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_old_version",
      "ios_constant_name": "AFEventParamOldVersion",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_new_version",
      "ios_constant_name": "AFEventParamNewVersion",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_review_text",
      "ios_constant_name": "AFEventParamReviewText",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_coupon_code",
      "ios_constant_name": "AFEventParamCouponCode",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_order_id",
      "ios_constant_name": "AFEventParamOrderId",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_1",
      "ios_constant_name": "AFEventParam1",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_2",
      "ios_constant_name": "AFEventParam2",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_3",
      "ios_constant_name": "AFEventParam3",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_4",
      "ios_constant_name": "AFEventParam4",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_5",
      "ios_constant_name": "AFEventParam5",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_6",
      "ios_constant_name": "AFEventParam6",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_7",
      "ios_constant_name": "AFEventParam7",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_8",
      "ios_constant_name": "AFEventParam8",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_9",
      "ios_constant_name": "AFEventParam9",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_param_10",
      "ios_constant_name": "AFEventParam10",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_departing_departure_date",
      "ios_constant_name": "AFEventParamDepartingDepartureDate",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_returning_departure_date",
      "ios_constant_name": "AFEventParamReturningDepartureDate",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_destination_list",
      "ios_constant_name": "AFEventParamDestinationList",
      "Type": "String[]"
    },
    {
      "event_parameter_name": "af_city",
      "ios_constant_name": "AFEventParamCity",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_region",
      "ios_constant_name": "AFEventParamRegion",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_country",
      "ios_constant_name": "AFEventParamCountry",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_departing_arrival_date",
      "ios_constant_name": "AFEventParamDepartingArrivalDate",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_returning_arrival_date",
      "ios_constant_name": "AFEventParamReturningArrivalDate",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_suggested_destinations",
      "ios_constant_name": "AFEventParamSuggestedDestinations",
      "Type": "String[]"
    },
    {
      "event_parameter_name": "af_travel_start",
      "ios_constant_name": "AFEventParamTravelStart",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_travel_end",
      "ios_constant_name": "AFEventParamTravelEnd",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_num_adults",
      "ios_constant_name": "AFEventParamNumAdults",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_num_children",
      "ios_constant_name": "AFEventParamNumChildren",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_num_infants",
      "ios_constant_name": "AFEventParamNumInfants",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_suggested_hotels",
      "ios_constant_name": "AFEventParamSuggestedHotels",
      "Type": "String[]"
    },
    {
      "event_parameter_name": "af_user_score",
      "ios_constant_name": "AFEventParamUserScore",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_hotel_score",
      "ios_constant_name": "AFEventParamHotelScore",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_purchase_currency",
      "ios_constant_name": "AFEventParamPurchaseCurrency",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_preferred_neighborhoods",
      "ios_constant_name": "AFEventParamPreferredNeighborhoods //array of string",
      "Type": "String[]"
    },
    {
      "event_parameter_name": "af_preferred_num_stops",
      "ios_constant_name": "AFEventParamPreferredNumStops",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_adrev_ad_type",
      "ios_constant_name": "AFEventParamAdRevenueAdType",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_adrev_network_name",
      "ios_constant_name": "AFEventParamAdRevenueNetworkName",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_adrev_placement_id",
      "ios_constant_name": "AFEventParamAdRevenuePlacementId",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_adrev_ad_size",
      "ios_constant_name": "AFEventParamAdRevenueAdSize",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_adrev_mediated_network_name",
      "ios_constant_name": "AFEventParamAdRevenueMediatedNetworkName",
      "Type": "String"
    },
    {
      "event_parameter_name": "af_preferred_price_range",
      "ios_constant_name": "AFEventParamPreferredPriceRange",
      "Type": "int[] - basically a tuple(min,max) but we'll use array of int and use two values"
    },
    {
      "event_parameter_name": "af_preferred_star_ratings",
      "ios_constant_name": "AFEventParamPreferredStarRatings",
      "Type": "int[] - basically a tuple(min,max) but we'll use array of int and use two values"
    }
  ]
exports.iosEvents=[
    {
      "Event name": "af_level_achieved",
      "ios_constant_name": "AFEventLevelAchieved"
    },
    {
      "Event name": "af_add_payment_info",
      "ios_constant_name": "AFEventAddPaymentInfo"
    },
    {
      "Event name": "af_add_to_cart",
      "ios_constant_name": "AFEventAddToCart"
    },
    {
      "Event name": "af_add_to_wishlist",
      "ios_constant_name": "AFEventAddToWishlist"
    },
    {
      "Event name": "af_complete_registration",
      "ios_constant_name": "AFEventCompleteRegistration"
    },
    {
      "Event name": "af_tutorial_completion",
      "ios_constant_name": "AFEventTutorial_completion"
    },
    {
      "Event name": "af_initiated_checkout",
      "ios_constant_name": "AFEventInitiatedCheckout"
    },
    {
      "Event name": "af_purchase",
      "ios_constant_name": "AFEventPurchase"
    },
    {
      "Event name": "af_rate",
      "ios_constant_name": "AFEventRate"
    },
    {
      "Event name": "af_search",
      "ios_constant_name": "AFEventSearch"
    },
    {
      "Event name": "af_spent_credits",
      "ios_constant_name": "AFEventSpentCredits"
    },
    {
      "Event name": "af_achievement_unlocked",
      "ios_constant_name": "AFEventAchievementUnlocked"
    },
    {
      "Event name": "af_content_view",
      "ios_constant_name": "AFEventContentView"
    },
    {
      "Event name": "af_list_view",
      "ios_constant_name": "AFEventListView"
    },
    {
      "Event name": "af_travel_booking",
      "ios_constant_name": "AFEventTravelBooking"
    },
    {
      "Event name": "af_share",
      "ios_constant_name": "AFEventShare"
    },
    {
      "Event name": "af_invite",
      "ios_constant_name": "AFEventInvite"
    },
    {
      "Event name": "af_login",
      "ios_constant_name": "AFEventLogin"
    },
    {
      "Event name": "af_re_engage",
      "ios_constant_name": "AFEventReEngage"
    },
    {
      "Event name": "af_update",
      "ios_constant_name": "AFEventUpdate"
    },
    {
      "Event name": "af_opened_from_push_notification",
      "ios_constant_name": "AFEventOpenedFromPushNotification"
    },
    {
      "Event name": "af_location_coordinates",
      "ios_constant_name": "AFEventLocation"
    },
    {
      "Event name": "af_customer_segment",
      "ios_constant_name": "AFEventCustomerSegment"
    },
    {
      "Event name": "af_subscribe",
      "ios_constant_name": "AFEventSubscribe"
    },
    {
      "Event name": "af_start_trial",
      "ios_constant_name": "AFEventStartTrial"
    },
    {
      "Event name": "af_ad_click",
      "ios_constant_name": "AFEventAdClick"
    },
    {
      "Event name": "af_ad_view",
      "ios_constant_name": "AFEventAdView"
    }
  ]
