import { Button, Box, Container, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import AndroidOutlinedIcon from "@mui/icons-material/AndroidOutlined";
import AppleIcon from "@mui/icons-material/Apple";
import { IProgress, ISessionProps, OSType } from "../components/IProgress";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../components/transition";
import { useTranslation } from "react-i18next";
import { amplitudeTrack } from "../../services/utils";

const ChooseOsPath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleClick = (os: OSType) => {
    amplitudeTrack("dj_continue_click", {
      title: "homepage",
      platform: os
    })
    const progressArr: IProgress[] = [
      { id: "appParams", text: "progress_appParams", order: 1 },
    ];
    const progressState: ISessionProps = {
      ...location.state,
      os,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    }; // Initialize progress with a copy of progressArr
    navigate(`/appparams`, { state: progressState });
  };

  return (
    <Container maxWidth={"lg"}>
      <Box
        textAlign={"center"}
        marginTop={5}
        marginBottom={5}
        padding={5}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        style={{ minHeight: "50vh" }}
      >
        <Typography variant="h1">
          {t("progressbar_chooseos")}
        </Typography>
        <Box display={"flex"} sx={{mt: 5}} gap={1} justifyContent={"center"}>
          <Tooltip title="Android" arrow>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{ width: "100px", height: "100px" }}
              onClick={() => handleClick(OSType.Android)}
            >
              <AndroidOutlinedIcon style={{ fontSize: 50 }} />
            </Button>
          </Tooltip>

          <Tooltip title="iOS" arrow>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{ width: "100px", height: "100px" }}
              onClick={() => handleClick(OSType.iOS)}
            >
              <AppleIcon style={{ fontSize: 35 }} />
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Container>
  );
};

export default transition(ChooseOsPath);
