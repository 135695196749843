import { ReactNode } from "react";

interface StyleComponentProps {
  children: ReactNode;
}

const StyleComponent: React.FC<StyleComponentProps> = ({ children }) => {
  const containerStyle: React.CSSProperties = {
    // backgroundColor: "#F8F8FF", // Offwhite color
    // border: "1px solid #ddd", // Border color
    // borderRadius: "8px",  
    marginTop:"40px",
};



  return <div style={containerStyle}>{children}</div>;
};

export default StyleComponent;
