import { Typography } from "@appsflyer/fe-ui-core";
import { useTranslation } from "react-i18next";
import { ISessionProps } from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Button } from "@mui/material";
import transition from "../../main/components/transition";
import { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { amplitudeTrack_continue } from "../../services/utils";

const DL_prepareUniversalLinks2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [extractedDomain, setExtractedDomain] = useState("");

  useEffect(() => {
    const onelink: URL = new URL(
      location.state.oneLinkURL.indexOf("http") > -1
        ? location.state.oneLinkURL
        : "https://" + location.state.oneLinkURL
    );
    setExtractedDomain(onelink.host);
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClick = () => {
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/ios/prepareURI", { state: progressState });
  };
  const steps = [
    {
      label: t("dl_6_ios_step_1_substep_title_a"),
      component: (
        <>
          <Typography>{t(`dl_6_ios_step_1_substep_a`)}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_step_1_substep_title_b"),
      component: (
        <>
          <Typography>{t(`dl_6_ios_step_1_substep_b`)}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_step_1_substep_title_c"),
      component: (
        <>
          <Typography>{t(`dl_6_ios_step_1_substep_c`)}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_step_1_substep_title_d"),
      component: (
        <>
          <Typography>
            {t(`dl_6_ios_step_1_substep_4_a`)}{" "}
            <b>{t("dl_6_ios_step_1_substep_4_Capabilities")}</b>{" "}
            {t(`dl_6_ios_step_1_substep_4_b`)}
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_step_1_substep_title_e"),
      component: (
        <>
          <Typography>
            {t(`dl_6_ios_step_1_substep_e`)}{" "}
            <b>{t("dl_6_ios_step_1_substep_5_domains")}</b>
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_6_ios_step_1_substep_title_f"),
      component: (
        <>
          <Typography>{t(`dl_6_ios_step_1_substep_f`)}</Typography>
          <Typography>
            <code>applinks:{extractedDomain}</code>
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography variant="h1">{t("dl_6_ios_prepare_app")}</Typography>
        </Box>

        <Typography variant="h2" gutterBottom>
          {t("dl_6_ios_step_a")}
        </Typography>

        <Typography variant="body1" paragraph>
          {t("dl_6_ios_step_1_description")}
        </Typography>
        <Typography variant="h3">{t("dl_6_ios_step_1_subtitle")}</Typography>

        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={5}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("StartSDK_back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("StartSDK_continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_prepareUniversalLinks2);
