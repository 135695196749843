import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Container, Grid, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  IProgress,
  ISessionProps,
  IntegrationPathType,
  OSType,
  SessionStatus,
} from "../components/IProgress";
import { PricingPlan, Typography } from "@appsflyer/fe-ui-core";
import transition from "../components/transition";
import { amplitudeTrack_continue, sendGtagOnce } from "../../services/utils";

const PREFIX = "PricingPlan";
const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  planContent: `${PREFIX}-planContent`,
  description: `${PREFIX}-description`,
  mostPopular: `${PREFIX}-mostPopular`,
};
const StyledPricingPlan = styled(PricingPlan)(() => ({
  [`&.${PricingPlan.classes.root}`]: {},
  [`& .${PricingPlan.classes.title}`]: {
    color: "#1976d2",
    maxWidth: "240px",
    height: 60,
  },
  [`& .${PricingPlan.classes.planContent}`]: {
    height: 80,
  },
  [`& .${PricingPlan.classes.description}`]: {
    textAlign: "left",
    height: "150px",
    minWidth: "240px",
  },
  [`& .${PricingPlan.classes.mostPopular}`]: {
    backgroundColor: "green",
  },
}));

const IntegrationPath = () => {
  const { t } = useTranslation();
  const [isStartDone, setIsStartDone] = useState(false);
  const [isInAppDone, setIsInAppDone] = useState(false);
  const [isDLDone, setIsDLDone] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const appId = location.state?.appID;
    const os = location.state?.os;

    // check if direct testing mode is on
    if (location.search && location.search.indexOf("testing") > -1) {
      if (location.search.indexOf("testing=true") > -1) {
        navigate("/integrationPath", {
          replace: true,
          state: location.state,
        });
        skipToTestNavigate();
      }
    }

    setIsStartDone(
      localStorage.getItem(
        `isComplete_${appId}_${os}_${IntegrationPathType.StartSDK}`
      ) == "true"
    );
    setIsInAppDone(
      localStorage.getItem(
        `isComplete_${appId}_${os}_${IntegrationPathType.InApp}`
      ) == "true"
    );
    setIsDLDone(
      localStorage.getItem(
        `isComplete_${appId}_${os}_${IntegrationPathType.DeepLink}`
      ) == "true"
    );
  }, []);

  const skipToTestNavigate = () => {
    const progressArr: IProgress[] = location.state?.progress.slice();

    progressArr.push({
      id: "start_installSDK",
      text: "progress_start_installSDK",
      order: 2,
      startTime: new Date(),
      updateTime: new Date(),
    });

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      path: IntegrationPathType.StartSDK,
      currentPage: location.state?.currentPage + 1,
    };

    // navigate(`/startsdk/${location.state?.os}/installsdk`, {
    //   state: progressState,
    // });
    switch (location.state.os as OSType) {
      case OSType.Android:
        progressArr.push({
          id: "startsdk_permissions",
          text: "progress_startsdk_permissions",
          order: 3,
          startTime: new Date(),
          updateTime: new Date(),
        });
        // navigate(`/startsdk/${location.state?.os}/setpermissions`, {
        //   state: progressState,
        // });
        // navigate(`/startsdk/${location.state?.os}/referrer`, {
        //   state: progressState,
        // });
        progressArr.push({
          id: "startsdk_setgcd",
          text: "progress_startsdk_setgcd",
          order: 4,
          startTime: new Date(),
          updateTime: new Date(),
        });
        // navigate(`/startsdk/${location.state?.os}/setGCD`, {
        //   state: progressState,
        // });
        break;
      case OSType.iOS:
        progressArr.push({
          id: "startsdk_setgcd",
          text: "progress_startsdk_setgcd",
          order: 3,
          startTime: new Date(),
          updateTime: new Date(),
        });
        // navigate(`/startsdk/${location.state?.os}/setGCD`, {
        //   state: progressState,
        // });
        break;
      default:
        break;
    }
    // // add startksdk page to history
    // navigate(`/startsdk/${location.state?.os}/startsdk`, {
    //   state: progressState,
    // });

    progressArr.push({
      id: "startsdk_startSDK",
      text: "progress_startsdk_startSDK",
      order: 5,
      startTime: new Date(),
    });
    progressState.sessionStatus = SessionStatus.ImplementedNotTested;
    // send gtag for implemented not test
    sendGtagOnce(
      "implemented_not_tested",
      "IntegrationPath",
      location.state.sessionId
    );

    amplitudeTrack_continue(progressState, { isSkip: true })

    navigate(`/startsdk/${location.state?.os}/testsdktype`, {
      state: progressState,
    });
  };

  const handleClick = (
    newPath: IntegrationPathType = IntegrationPathType.unset,
    skipToTest: boolean = false
  ) => {
    const progressArr: IProgress[] = location.state?.progress.slice();

    switch (newPath) {
      case IntegrationPathType.StartSDK:
        if (skipToTest) {
          skipToTestNavigate();
          return;
        }
        progressArr.push({
          id: "start_installSDK",
          text: "progress_start_installSDK",
          order: 2,
          startTime: new Date(),
          updateTime: new Date(),
        });
        break;
      case IntegrationPathType.InApp:
        progressArr.push({
          id: "inapp_createEvent",
          text: "progress_inapp_createEvent",
          order: 2,
        });
        break;
      case IntegrationPathType.DeepLink:
        progressArr.push({
          id: "dl_prepareApp",
          text: "progress_dl_prepareApp",
          order: 2,
        });
        break;
      default:
        break;
    }

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      path: newPath,
      currentPage: location.state?.currentPage + 1,
    };

    switch (newPath) {
      case IntegrationPathType.StartSDK:
        navigate(`/startsdk/${location.state?.os}/installsdk`, {
          state: progressState,
        });
        break;
      case IntegrationPathType.InApp:
        navigate(`/inapp/${location.state?.os}/sendinapp`, {
          state: progressState,
        });
        break;
      case IntegrationPathType.DeepLink:
        navigate(`/deeplink/${location.state?.os}/prepare`, {
          state: progressState,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={5}>
          <Typography variant="h1">
            {t("StartSDK_appParams_ios_integrate")}
          </Typography>
          <Typography variant="h3" sx={{ marginRight: 1 }}>
            {t("StartSDK_appParams_ios_integrate_sub")}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid item>
              <StyledPricingPlan
                title={t("StartSDK_appParams_ios_installSDK")}
                description={t("StartSDK_appParams_ios_installSDK_desc")}
                recommendedText={
                  isStartDone ? t("integrated_successfully") : null
                }
                classes={{ ...classes }}
                planContent={
                  <>
                    <Grid
                      container
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid item>
                        <Typography>
                          <Button
                            onClick={() => {
                              handleClick(IntegrationPathType.StartSDK, true);
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            {t("StartSDK_appParams_ios_intg_skiptotest")}
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }
                ButtonProps={{
                  children: t("StartSDK_choosepath"),
                  onClick: () => {
                    handleClick(IntegrationPathType.StartSDK);
                  },
                }}
              />
            </Grid>
            <Grid item>
              <StyledPricingPlan
                title={t("StartSDK_appParams_ios_inApp")}
                description={t("StartSDK_appParams_ios_inApp_desc")}
                recommendedText={
                  isInAppDone ? t("integrated_successfully") : null
                }
                classes={{ ...classes }}
                planContent={
                  <>
                    <Grid container sx={{ justifyContent: "center" }}>
                      <Grid item>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <Typography>
                            <b>{t("StartSDK_appParams_ios_req")}:</b>{" "}
                          </Typography>
                          <Button
                            onClick={() => {
                              handleClick(IntegrationPathType.StartSDK);
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            {t("StartSDK_appParams_ios_intg")}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                }
                ButtonProps={{
                  children: t("StartSDK_choosepath"),
                  onClick: () => {
                    handleClick(IntegrationPathType.InApp);
                  },
                }}
              />
            </Grid>
            <Grid item>
              <StyledPricingPlan
                title={t("StartSDK_appParams_ios_deepLinking")}
                description={t("StartSDK_appParams_ios_deepLinking_desc")}
                recommendedText={isDLDone ? t("integrated_successfully") : null}
                classes={{ ...classes }}
                planContent={
                  <>
                    <Grid container sx={{ justifyContent: "center" }}>
                      <Grid item>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <Typography>
                            <b>{t("StartSDK_appParams_ios_req")}:</b>{" "}
                          </Typography>
                          <Button
                            onClick={() => {
                              handleClick(IntegrationPathType.StartSDK);
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            {t("StartSDK_appParams_ios_intg")}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                }
                ButtonProps={{
                  children: t("StartSDK_choosepath"),
                  onClick: () => {
                    handleClick(IntegrationPathType.DeepLink);
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("StartSDK_back")}
        </Button>
      </Box>
    </Container>
  );
};
export default transition(IntegrationPath);
