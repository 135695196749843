import { useEffect, useState } from "react";
import { Box, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock, { ICodeBlock } from "../../main/components/codeblock";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, TextField } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const DL_prepare2Android = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [appId, setAppId] = useState<string>("");
  const [uriScheme, setUriScheme] = useState("");
  const code = `<?xml version="1.0" encoding="utf-8"?>
<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    xmlns:tools="http://schemas.android.com/tools"
    package="${appId}">
    <application
        android:theme="@style/AppTheme">
        <activity
            android:name=".MainActivity"
            android:exported="true"
            android:launchMode="singleTask">
          <intent-filter>
            <action android:name="android.intent.action.VIEW" />
            <category android:name="android.intent.category.DEFAULT" />
            <category android:name="android.intent.category.BROWSABLE" />
            <data
                android:host=""
                android:scheme="" />
          </intent-filter>
        </activity>
    </application>
</manifest>`;
  const [codeBlockProps, setCodeBlockProps] = useState<ICodeBlock>({
    code: code,
    language: "xml", // Assuming it's XML, change it to the correct language if needed
    showLineNumbers: true,
    highlight: "19-26",
  });
  const uriRegex = /^[a-zA-Z][a-zA-Z0-9+\-.]*:/;

  const testUri = (scheme: string) => {
    if (scheme.indexOf("http") > -1) return false;
    return uriRegex.test(scheme);
  };

  useEffect(() => {
    const uriScheme = localStorage.getItem(
      `uriScheme_${location.state?.os}_${location.state?.appID}`
    );
    if (uriScheme) {
      setUriScheme(JSON.parse(uriScheme));
    }

    const scheme = uriScheme?.substring(0, uriScheme.indexOf("://"));
    const path =
      scheme && uriScheme?.substring(uriScheme.indexOf("://") + 3).length
        ? uriScheme.substring(uriScheme.indexOf("://") + 3).split("/")[0]
        : "";
    const onelinkURL = location.state.oneLinkURL;
    const newSubdomain = onelinkURL.startsWith("http")
      ? new URL(onelinkURL).hostname
      : new URL("http://" + onelinkURL).hostname;

    const newCode = `<?xml version="1.0" encoding="utf-8"?>
<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    xmlns:tools="http://schemas.android.com/tools"
    package="${appId}">
    <application
        android:theme="@style/AppTheme">
        <activity
            android:name=".MainActivity"
            android:exported="true"
            android:launchMode="singleTask">
          <intent-filter android:autoVerify="true">
            <action android:name="android.intent.action.VIEW" />
            <category android:name="android.intent.category.DEFAULT" />
            <category android:name="android.intent.category.BROWSABLE" />
            <data
                android:host="${newSubdomain}"
                android:scheme="https" />
          </intent-filter>
          <intent-filter>
            <action android:name="android.intent.action.VIEW" />
            <category android:name="android.intent.category.DEFAULT" />
            <category android:name="android.intent.category.BROWSABLE" />
            <data
                android:host="${path}"
                android:scheme="${scheme ?? ""}" />
          </intent-filter>
        </activity>
    </application>
</manifest>`;
    setCodeBlockProps({
      code: newCode,
      language: "xml", // Assuming it's XML, change it to the correct language if needed
      showLineNumbers: true,
      highlight: "19-26",
    });
  }, []);

  useEffect(() => {
    const scheme = uriScheme?.substring(0, uriScheme.indexOf("://"));
    const path =
      scheme && uriScheme?.substring(uriScheme.indexOf("://") + 3).length
        ? uriScheme.substring(uriScheme.indexOf("://") + 3).split("/")[0]
        : "";
    const onelinkURL = location.state.oneLinkURL;
    const newSubdomain = onelinkURL.startsWith("http")
      ? new URL(onelinkURL).hostname
      : new URL("http://" + onelinkURL).hostname;

    const newCode = `<?xml version="1.0" encoding="utf-8"?>
<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    xmlns:tools="http://schemas.android.com/tools"
    package="${appId}">
    <application
        android:theme="@style/AppTheme">
        <activity
            android:name=".MainActivity"
            android:exported="true"
            android:launchMode="singleTask">
          <intent-filter android:autoVerify="true">
            <action android:name="android.intent.action.VIEW" />
            <category android:name="android.intent.category.DEFAULT" />
            <category android:name="android.intent.category.BROWSABLE" />
            <data
                android:host="${newSubdomain}"
                android:scheme="https" />
          </intent-filter>
          <intent-filter>
            <action android:name="android.intent.action.VIEW" />
            <category android:name="android.intent.category.DEFAULT" />
            <category android:name="android.intent.category.BROWSABLE" />
            <data
                android:host="${path}"
                android:scheme="${scheme ?? ""}" />
          </intent-filter>
        </activity>
    </application>
</manifest>`;
    setCodeBlockProps({
      code: newCode,
      language: "xml", // Assuming it's XML, change it to the correct language if needed
      showLineNumbers: true,
      highlight: "19-26",
    });
  }, [uriScheme]);

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setAppId(state?.appID ?? "");
    }
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = location.state?.progress.slice();
    // progressArr.push({
    //   id: "dl_implementCode",
    //   text: "Implement code",
    //   order: Math.max(...progressArr.map((o) => o.order)) + 1,
    // });
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };

    if (!uriScheme || testUri(uriScheme)) {
      localStorage.setItem(
        `uriScheme_${location.state?.os}_${location.state?.appID}`,
        JSON.stringify(uriScheme)
      );
      if (uriScheme) progressState.uriScheme = uriScheme;
    }
    amplitudeTrack_continue(location.state);

    navigate("/deeplink/android/prepare3", { state: progressState });
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography sx={{ mb: 2 }} variant="h1">
            {t("dl_page7_android_prepareAppTitle")}
          </Typography>
          <Typography variant="h2">
            {t("dl_page7_android_optionalURIScheme")}
          </Typography>
        </Box>
        <Box
          // p={2}
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems={"flex-start"}
          flexDirection={"column"}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <TextField
              label={t("dl_4_ios_uri_scheme_label")}
              placeholder={"myappuri://launch"}
              value={uriScheme}
              inputProps={{ className: "amp-unmask" }}
              error={uriScheme.length > 0 && !testUri(uriScheme)}
              onChange={(e) => {
                setUriScheme(e.target.value);
                amplitudeTrack_inputChange(
                  location.state,
                  InputType.TextField,
                  "uriScheme",
                  e.target.value
                );
              }}
              // onPaste={(e) => setUriScheme(e.target.value)}
              variant="outlined"
              size="xl"
            />
          </Box>
          <AmplitudeLink
            my={2}
            href={`https://${
              location.state.lang != "en" ? location.state.lang + "." : ""
            }dev.appsflyer.com/hc/docs/dl_android_init_setup#procedures-for-uri-scheme`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("dl_4_ios_uri_scheme_link")}
          </AmplitudeLink>
        </Box>
        {!uriScheme ||
          (testUri(uriScheme) && (
            <>
              <Typography variant="h3">
                {t("dl_page7_android_addURISchemeIntentFilter")}
              </Typography>
              <Typography>
                {t("dl_page7_android_addURISchemeIntentFilterInstructions")}
              </Typography>
              <br />

              <DJCodeBlock {...codeBlockProps} />
            </>
          ))}
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_prepare2Android);
