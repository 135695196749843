import { Button, Box, Container, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../components/transition";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box
          textAlign={"center"}
          marginTop={10}
          padding={5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Typography variant="h1">{t("notfound_wecouldnot")}</Typography>
          <Box marginY={3} display={"flex"} justifyContent={"center"}>
            <img
              style={{ width: "300px", display: "flex", alignSelf: "center" }}
              src={process.env.PUBLIC_URL + "/images/undraw_take.svg"}
              alt={t("homepage_logoAlt")}
            />
          </Box>

          <Box display={"flex"} marginTop={1} gap={1} justifyContent={"center"}>
            <Tooltip title={t("homepage_start")} arrow>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => navigate("/")}
              >
                <Typography>{t("notfound_backhome")}</Typography>
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box marginTop={10}></Box>
    </Container>
  );
};

export default transition(NotFound);
