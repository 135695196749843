import { DeeplinkType } from "../deeplinking/DeferredOrDirect";
import { ISessionProps } from "../main/components/IProgress";
import * as amplitude from "@amplitude/analytics-browser";

export const creatDLObject = (state: ISessionProps, json) => {
  if (json === null || Object.keys(json).length === 0) {
    const queryParams = state.oneLinkURL.split("?")[1] ?? [];
    const urlParams = new URLSearchParams(queryParams);
    json = Object.fromEntries(urlParams);
  }
  if (state.deeplinkType == DeeplinkType.Deferred) {
    const newObj = {
      deep_link_value: json?.deep_link_value,
      is_deferred: true,
    };
    const keysToAdd = ["af_sub3", "af_sub1", "af_sub4", "af_sub5", "af_sub2"];
    keysToAdd.forEach((key) => {
      newObj[key] = "";
    });

    // add deep_link_sub[1-10] only if they exist
    for (let index = 1; index <= 10; index++) {
      if (json["deep_link_sub" + index]) {
        newObj["deep_link_sub" + index] = json["deep_link_sub" + index];
      }
    }
    return newObj;
  } else {
    return {
      ...json,
      is_deferred: false,
    };
  }
};

const oneLinkRegex =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{4}\/[a-zA-Z0-9]{1,}/m;
const oneLinkRegexLonglink =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{4}[?]{1,}[a-zA-Z0-9]{1,}/m;
const oneLinkRegexLonglinkWithSlash =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{4}[/]{1,}[?]{1,}[a-zA-Z0-9]{1,}/m;

export const checkOnelinkRegex = (oneLinkURL) => {
  return (
    oneLinkRegex.test(oneLinkURL) ||
    oneLinkRegexLonglink.test(oneLinkURL) ||
    oneLinkRegexLonglinkWithSlash.test(oneLinkURL)
  );
};

export const sendGtagOnce = (
  eventName: string,
  screenName: string,
  sessionId: string
) => {
  const isSent = localStorage.getItem(`gtag_isSent_${eventName}_${sessionId}`);
  if (!JSON.parse(isSent)) {
    gtag("event", eventName, {
      app_name: "devjourney",
      screen_name: screenName,
      content_id: sessionId,
    });

    localStorage.setItem(
      `gtag_isSent_${eventName}_${sessionId}`,
      JSON.stringify(true)
    );
  }
};

// amplitude events

export const amplitudeTrack = (eventName: string, eventProperties: object) => {
  amplitude.track(eventName, eventProperties);
};

export const amplitudeTrack_view = (state: ISessionProps) => {
  const pagename =
    location.pathname.length > 2 ? location.pathname.substring(1) : "homepage";

  const trackObject = {
    sessionId: state.sessionId,
    pathname: location.pathname,
    title: state.progress.length
      ? state?.progress[state.progress.length - 1]?.text
      : pagename,
    platform: state?.os,
    sessionStatus: state.sessionStatus,
    appID: state?.appID,
  };

  amplitudeTrack("dj_page_view", trackObject);
};

export const amplitudeTrack_continue = (
  state: ISessionProps,
  selection?: object
) => {
  const pagename =
    location.pathname.length > 2 ? location.pathname.substring(1) : "homepage";

  const trackObject = {
    sessionId: state.sessionId,
    pathname: location.pathname,
    title: state.progress.length
      ? state?.progress[state.progress.length - 1]?.text
      : pagename,
    platform: state?.os,
    sessionStatus: state.sessionStatus,
    appID: state?.appID,
    selection,
  };

  amplitudeTrack("dj_continue_click", trackObject);
};

export const amplitudeTrack_getResults = (state: ISessionProps) => {
  const pagename =
    location.pathname.length > 2 ? location.pathname.substring(1) : "homepage";

  const trackObject = {
    sessionId: state.sessionId,
    pathname: location.pathname,
    title: state.progress.length
      ? state?.progress[state.progress.length - 1]?.text
      : pagename,
    platform: state?.os,
    appID: state?.appID,
    sessionStatus: state.sessionStatus,
    completionResult: state.completionResult,
    sdkTestType: state.sdkTestType,
    installUID: state.installUID,
    deviceId: state.deviceId,
  };

  amplitudeTrack("dj_get_results", trackObject);
};

export const amplitudeTrack_linkClick = (
  state: ISessionProps,
  link: string
) => {
  const pagename =
    location.pathname.length > 2 ? location.pathname.substring(1) : "homepage";

  const trackObject = {
    sessionId: state.sessionId,
    appID: state?.appID,
    pathname: location.pathname,
    title: state.progress.length
      ? state?.progress[state.progress.length - 1]?.text
      : pagename,
    platform: state?.os,
    link,
  };

  amplitudeTrack("dj_link_click", trackObject);
};

export const amplitudeTrack_changeToManual = (state: ISessionProps) => {
  const pagename =
    location.pathname.length > 2 ? location.pathname.substring(1) : "homepage";

  const trackObject = {
    sessionId: state.sessionId,
    appID: state?.appID,
    pathname: location.pathname,
    title: state.progress.length
      ? state?.progress[state.progress.length - 1]?.text
      : pagename,
    platform: state?.os,
  };

  amplitudeTrack("dj_change_to_manual", trackObject);
};

export const amplitudeTrack_login = (state: ISessionProps, appId) => {
  const pagename =
    location.pathname.length > 2 ? location.pathname.substring(1) : "homepage";

  const trackObject = {
    sessionId: state.sessionId,
    pathname: location.pathname,
    title: state.progress.length
      ? state?.progress[state.progress.length - 1]?.text
      : pagename,
    platform: state?.os,
    appID: appId,
  };

  amplitudeTrack("dj_login_success", trackObject);
};

export enum InputType {
  TextField = "textField",
  Radio = "radio",
  Select = "select",
}

export const amplitudeTrack_inputChange = (
  state: ISessionProps,
  inputType: InputType,
  inputName: string,
  inputValue
) => {
  const pagename =
    location.pathname.length > 2 ? location.pathname.substring(1) : "homepage";

  const trackObject = {
    sessionId: state.sessionId,
    pathname: location.pathname,
    title: state.progress.length
      ? state?.progress[state.progress.length - 1]?.text
      : pagename,
    platform: state?.os,
    appID: state?.appID,
    inputType,
    inputName,
    inputValue,
  };

  amplitudeTrack("dj_input_change", trackObject);
};
