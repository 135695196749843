// import { createTheme } from '@mui/material/styles';
import { commonThemeSettings } from "@appsflyer/fe-ui-theme";
import { createTheme } from '@mui/material/styles';

const theme = createTheme(commonThemeSettings, {
  typography: {
    h1: {
      margin: "unset",
    },
    h2: {
      margin: "unset",
    },
    h3: {
      margin: "unset",
    },
    body1: {
      margin: "unset",
    },
  },
  palette: {
    primary: {
      main: "#F6F6F6",
    },
    secondary: {
      main: "#F6F6F6",
    },
    error: {
      main: "#D52553",
    },
    warning: {
      main: "#E16D0E",
    },
    success: {
      main: "#00805E",
    },
    info: {
      main: "#2C99C1",
    },
  }
});

export default theme;
