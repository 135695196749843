import React from "react";
import { CopyBlock, dracula } from 'react-code-blocks';
import './codeBlockStyle.css'

export interface ICodeBlock {
    code: string;
    language: string; 
    highlight?: string; 
    showLineNumbers: boolean;
    wrapLongLines?: boolean;
}

export default function DJCodeBlock(props: ICodeBlock) {
    
    return (
        <div className="code-block-container">
            <CopyBlock
                customStyle={{overflow: 'auto', minHeight: '40px', maxWidth: "700px"}}
                text={props.code}
                language={props.language}
                showLineNumbers={props.showLineNumbers}
                highlight={props.highlight}
                theme={dracula}
                wrapLongLines={props.wrapLongLines}
            />
        </div>

    );
  }