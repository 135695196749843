import { useEffect, useState } from "react";
import { Button, Box, Container, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IProgress,
  ISessionProps,
  OSType,
  SessionStatus,
} from "../components/IProgress";
import { v4 as uuidv4 } from "uuid";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../components/transition";
import { useTranslation } from "react-i18next";
import { amplitudeTrack } from "../../services/utils";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isTestingMode, setIsTestingMode] = useState(false);

  useEffect(() => {
    // check if direct testing mode is on
    if (location.search && location.search.indexOf("testing") > -1) {
      if (location.search.indexOf("testing=true") > -1) {
        setIsTestingMode(true);
      } else {
        setIsTestingMode(false);
      }
    }

    const progressArr: IProgress[] = [
      // { id: "startPage", text: "Start Page", order: 1 },
    ];

    const progressState: ISessionProps = {
      sessionId: uuidv4(),
      startTime: new Date(),
      updateTime: new Date(),
      os: OSType.unselected,
      progress: progressArr,
      sessionStatus: SessionStatus.Incomplete,
      currentPage: 1,
    }; // Initialize progress with a copy of progressArr

    navigate("/", {
      replace: true,
      state: progressState,
    });
  }, []);

  const handleClick = () => {
    amplitudeTrack("dj_continue_click", {
      title: "homepage",
    });

    if (location.search && location.search.indexOf("sourceos") > -1) {
      let os: OSType = OSType.unselected;

      if (location.search.indexOf("sourceos=ios") > -1) {
        os = OSType.iOS;
      }

      if (location.search.indexOf("sourceos=android") > -1) {
        os = OSType.Android;
      }

      if (os !== OSType.unselected) {
        const progressArr: IProgress[] = [
          // { id: "startPage", text: "Start Page", order: 1 },
        ];

        const progressState: ISessionProps = {
          sessionId: uuidv4(),
          startTime: new Date(),
          updateTime: new Date(),
          os: OSType.unselected,
          progress: progressArr,
          currentPage: 1,
          sessionStatus: SessionStatus.Incomplete,
        }; // Initialize progress with a copy of progressArr

        const progressArrRedirect: IProgress[] = [
          { id: "appParams", text: "progress_appParams", order: 1 },
        ];
        const progressStateRedirect: ISessionProps = {
          ...progressState,
          os,
          progress: progressArrRedirect,
          currentPage: 2,
        }; // Initialize progress with a copy of progressArr
        navigate(`/appparams${location.search}`, { state: progressStateRedirect });
      }
    } else {
      const progressArr: IProgress[] = [
        { id: "chooseOS", text: "progress_chooseos", order: 0 },
      ];
      const progressState: ISessionProps = {
        startTime: new Date(),
        updateTime: new Date(),
        sessionId: uuidv4(),
        os: OSType.unselected,
        progress: progressArr,
        currentPage: 2,
        sessionStatus: SessionStatus.Incomplete,
      };

      const lsLang = localStorage.getItem("language");
      if (lsLang) {
        const parsedLang = JSON.parse(lsLang);
        progressState.lang = parsedLang?.value;
      } else {
        progressState.lang = "en";
      }

      navigate(`/chooseospath`, { state: progressState });
    }
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box
          textAlign={"center"}
          marginTop={10}
          padding={5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Typography variant="h1" sx={{ mb: 1 }}>
            {t("homepage_title")}
          </Typography>
          <Typography variant="h2">{t("homepage_subtitle")}</Typography>
          {/* picture */}
          <Box display={"flex"} justifyContent={"center"} mt={5}>
            <img
              style={{ width: "300px", display: "flex", alignSelf: "center" }}
              src={process.env.PUBLIC_URL + "/images/DJ_illustratration.svg"}
              alt={t("homepage_logoAlt")}
            />
          </Box>
          <Box display={"flex"} mt={8} gap={1} justifyContent={"center"}>
            <Tooltip title={t("homepage_start")} arrow>
              <Button
                variant="contained"
                color="primary"
                // sx={{ p: 3 }}
                onClick={() => handleClick()}
              >
                {isTestingMode
                  ? t("startSDK_steps_android_TestIntegration")
                  : t("homepage_start")}
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box marginTop={10}></Box>
    </Container>
  );
};

export default transition(Home);
