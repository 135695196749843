import { useEffect, useState } from "react";

import { Box, useMediaQuery } from "@mui/material";
import AndroidOutlinedIcon from "@mui/icons-material/AndroidOutlined";
import AppleIcon from "@mui/icons-material/Apple";
import { IProgress, ISessionProps, OSType } from "./IProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography, Collapse } from "@appsflyer/fe-ui-core";
import "./Progress.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Progress = (props: ISessionProps) => {
  const location = useLocation();
  if (!props.progress || props.progress.length === 0) {
    return null; // Do not render Timeline if there's no progress
  }
  const notMobile = useMediaQuery("(min-width:800px)");
  const [isCollapseOpen, setIsCollapseOpen] = useState(notMobile);
  const [prog, setProg] = useState<IProgress[]>([]);

  useEffect(() => {
    setProg(location.state?.progress);
  }, [location.state]);

  // const sortedProgressArr = [...props.progress].sort(
  //   (a, b) => a.order - b.order
  // );
  const { t } = useTranslation();
  const ariaLabelPrefix = isCollapseOpen ? "Show Less" : "Show More";

  return (
    <Box maxWidth={{ xs: 200, md: 1200 }}>
      {notMobile && (
        <Stepper
          activeStep={props.progress.length}
          orientation={notMobile ? "horizontal" : "vertical"}
          style={{
            backgroundColor: "#ffffff00 !important",
            padding: "15px 12px",
            margin: "auto",
          }}
          className={"stepper"} // default ''
        >
          {props.os === OSType.Android ? (
            <Step>
              <StepLabel>
                <Typography style={{ display: "flex", gap: 5 }}>
                  <AndroidOutlinedIcon />
                  {t("progressbar_chooseos")}
                </Typography>
              </StepLabel>
            </Step>
          ) : (
            props.os === OSType.iOS && (
              <Step>
                <StepLabel>
                  <Typography style={{ display: "flex", gap: 5 }}>
                    <AppleIcon />
                    {t("progressbar_chooseos")}
                  </Typography>
                </StepLabel>
              </Step>
            )
          )}
          {prog.map((item) => (
            <Step key={item.id}>
              <StepLabel>{t(item.text)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {!notMobile && (
        <Collapse
          buttonVariant="text"
          isOpen={isCollapseOpen}
          onClick={() => setIsCollapseOpen((o) => !o)}
          title={t("progressbar_showsteps")}
          ButtonProps={{
            IconProps: {
              "aria-label": `${ariaLabelPrefix} Top Expand Icon`,
            },
          }}
        >
          {/* {props.os !== OSType.unselected && ( */}
          <Stepper
            activeStep={props.progress.length}
            orientation={notMobile ? "horizontal" : "vertical"}
            style={{ backgroundColor: "#ffffff00 !important" }}
            className={"stepper"} // default ''
          >
            {props.os === OSType.Android ? (
              <Step>
                <StepLabel>
                  <Typography style={{ display: "flex", gap: 5 }}>
                    <AndroidOutlinedIcon />
                    {t("progressbar_chooseos")}
                  </Typography>
                </StepLabel>
              </Step>
            ) : (
              props.os === OSType.iOS && (
                <Step>
                  <StepLabel>
                    <Typography style={{ display: "flex", gap: 5 }}>
                      <AppleIcon />
                      {t("progressbar_chooseos")}
                    </Typography>
                  </StepLabel>
                </Step>
              )
            )}
            {prog.map((item) => (
              <Step key={item.id}>
                <StepLabel>{t(item.text)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* )} */}
        </Collapse>
      )}
    </Box>
  );
};

export default Progress;
