/* eslint-disable */

import { ChangeEvent, useEffect, useState } from "react";
import { androidEvents, androidParams } from "./arrays";
import {
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock, { ICodeBlock } from "../../main/components/codeblock";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import SendInappEventParamForm, { IOption } from "../SendInappEventParam";
import { Typography, TextField, Select } from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import transition from "../../main/components/transition";
import ResponseListenerExplanation from "../../main/components/ResponseListenerExp";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const TestSDKIntegrationStepsOneTOThreeAndroid = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const { t } = useTranslation();

  // Radio group for the first section
  const [selectedRadioOne, setSelectedRadioOne] = useState("Predefined");
  const [isCustomOne, setIsCustomOne] = useState(false);
  const [chosenEventName, setChosenEventName] = useState(null);
  const [customEventName, setCustomEventName] = useState<string>("");
  const [eventParam1, setEventParam1] = useState<undefined | string>("");
  const [eventParam2, setEventParam2] = useState<undefined | string>("");
  const [eventParam3, setEventParam3] = useState<undefined | string>("");
  const [eventParam4, setEventParam4] = useState<undefined | string>("");
  const [eventParam5, setEventParam5] = useState<undefined | string>("");
  const [paramAdded, setParamAdded] = useState(false);
  const [paramCount, setParamCount] = useState(1);
  const [responseListenerRequired, setResponseListenerRequired] =
    useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddParameter = () => {
    if (paramCount < 5) {
      setParamCount((prevCount) => prevCount + 1);
      setParamAdded(true); // Set the flag when a parameter is added
    }
    switch (paramCount + 1) {
      case 2:
        setIsShowEventParam2(true);
        break;
      case 3:
        setIsShowEventParam3(true);
        break;
      case 4:
        setIsShowEventParam4(true);
        break;
      case 5:
        setIsShowEventParam5(true);
        break;
      default:
        break;
    }
  };

  const [isShowEventParam2, setIsShowEventParam2] = useState(false);
  const [isShowEventParam3, setIsShowEventParam3] = useState(false);
  const [isShowEventParam4, setIsShowEventParam4] = useState(false);
  const [isShowEventParam5, setIsShowEventParam5] = useState(false);

  const [generatedEventCode, setGeneratedEventCode] =
    useState<string>(`Map<String, Object> eventValues = new HashMap<String, Object>();
eventValues.put(AFInAppEventParameterName.<< Event Param  >>, <<PLACE_HOLDRER_FOR_PARAM_VALUE>>);
AppsFlyerLib.getInstance().logEvent(getApplicationContext(),
    << Event Name  >>, eventValues);`);

  useEffect(() => {
    // Update generatedEventCode based on selectedRadioTwo
    const baseCode = `AppsFlyerLib.getInstance().logEvent(getApplicationContext(),
    ${
      (chosenEventName && !isCustomOne) || (customEventName && isCustomOne)
        ? isCustomOne
          ? customEventName + `, eventValues);` ??
            `<< Event Param  >>, eventValues);`
          : chosenEventName.value + `, eventValues);` ??
            `<< Event Param  >>, eventValues);`
        : `<< Event Name  >>, eventValues);`
    }`;

    const paramLines = Array(paramCount)
      .fill(0)
      .map((_, index) => {
        const eventParam = eval(`eventParam${index + 1}`);
        return index === 0
          ? `${
              eventParam !== ""
                ? `eventValues.put(AFInAppEventParameterName.${eventParam},`
                : "eventValues.put(AFInAppEventParameterName.<< Event Param  >>,"
            } <<PLACE_HOLDRER_FOR_PARAM_VALUE>>);`
          : isShowEventParam2 && eventParam !== ""
          ? `eventValues.put(AFInAppEventParameterName.${eventParam}, <<PLACE_HOLDRER_FOR_PARAM_VALUE>>);`
          : "";
      })
      .filter(Boolean);

    const updatedGeneratedEventCode = `Map<String, Object> eventValues = new HashMap<String, Object>();
${paramLines.join("\n")}
${baseCode}`;

    setGeneratedEventCode(updatedGeneratedEventCode);
    setParamAdded(false);
  }, [
    chosenEventName,
    customEventName,
    isCustomOne,
    ...Array(5)
      .fill(0)
      .map((_, index) => eval(`eventParam${index + 1}`)),
    isShowEventParam2,
    paramAdded,
  ]);

  const handleRadioChangeOne = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "isCustomEventParamName",
      event
    );
    setIsCustomOne(value === "custom");
    setSelectedRadioOne(value);
  };

  useEffect(() => {
    const state: ISessionProps = location.state as ISessionProps;
    setProgress(state?.progress);
  }, [location.state]);

  const { os } = location.state || { os: "" };
  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr[progressArr.length - 1].isListener = responseListenerRequired;

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      eventName: isCustomOne ? customEventName : chosenEventName.label,
      currentPage: location.state?.currentPage + 1,
      eventParams: [
        eventParam1,
        eventParam2,
        eventParam3,
        eventParam4,
        eventParam5,
      ]
        .filter((x) => x)
        .map(
          (param) =>
            // look for the parameter within the param list and return the event parameter name
            // (unless it's custom and then the original value is returned)
            androidParams.find((x) => x.android_constant_name === param)
              ?.event_parameter_name ?? param
        ),
    };

    amplitudeTrack_continue(location.state, {
      responseListenerRequired,
      eventName: isCustomOne ? customEventName : chosenEventName.label,
      eventParams: [
        eventParam1,
        eventParam2,
        eventParam3,
        eventParam4,
        eventParam5,
      ],
    });

    navigate(`/inapp/${os.toLowerCase()}/testtype`, {
      state: progressState,
    });
  };

  const codeProps: ICodeBlock = {
    code: `import com.appsflyer.AppsFlyerLib;`,
    language: "java",
    showLineNumbers: true,
  };
  const codeProps2: ICodeBlock = {
    code: `import com.appsflyer.AppsFlyerLib;
import com.appsflyer.AFInAppEventType; // Predefined event names
import com.appsflyer.AFInAppEventParameterName; // Predefined parameter names`,
    language: "java",
    highlight: "2-3",
    showLineNumbers: true,
  };

  const options: IOption[] = androidEvents.map((event, index) => ({
    value: event["android_constant_name"],
    label: event["Event name"],
  }));

  const [inappCode, setInappCode] =
    useState<string>(`Map<String, Object> eventValues = new HashMap<String, Object>();
  eventValues.put(AFInAppEventParameterName.<< Event Param  >>, <<PLACE_HOLDRER_FOR_PARAM_VALUE>>);
  AppsFlyerLib.getInstance().logEvent(getApplicationContext(),
      << Event Name  >>, eventValues);`);
  const [inappCodeWListener, setInappCodeWListener] = useState<string>("");
  const [responseListRequired, setResponseListRequired] =
    useState<string>("No");

  const handleResponseChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setResponseListRequired(event.target.value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "responseListRequired",
      event.target.value
    );
    updateEventCode();
  };

  const updateEventCode = () => {
    const code1 = `import com.appsflyer.AppsFlyerLib;
import com.appsflyer.AFInAppEventType; // Predefined event names
import com.appsflyer.AFInAppEventParameterName; // Predefined parameter names
import com.appsflyer.attribution.AppsFlyerRequestListener;

${inappCode.replace(
  `eventValues);`,
  `eventValues,
      new AppsFlyerRequestListener() {`
)}
        @Override
        public void onSuccess() {
          // YOUR CODE UPON SUCCESS
        }
        @Override
        public void onError(int i, String s) {
          // YOUR CODE FOR ERROR HANDLING
        }
      }
  );`;
    const code2 = `import com.appsflyer.AppsFlyerLib;
import com.appsflyer.AFInAppEventType; // Predefined event names
import com.appsflyer.AFInAppEventParameterName; // Predefined parameter names

${inappCode}`;

    if (responseListRequired == "No") {
      setInappCodeWListener(code1);
    } else {
      setInappCodeWListener(code2);
    }
  };

  useEffect(() => {
    if (generatedEventCode) {
      setInappCode(generatedEventCode);
      setInappCodeWListener(`import com.appsflyer.AppsFlyerLib;
import com.appsflyer.AFInAppEventType; // Predefined event names
import com.appsflyer.AFInAppEventParameterName; // Predefined parameter names

${generatedEventCode}`);
    }
  }, [generatedEventCode]);

  const steps = [
    {
      label: t("ip_AppEvents_android_stepOneHeading"),
      component: (
        <>
          <Typography>
            {t("ip_AppEvents_android_stepOneDescription")}
            <AmplitudeLink
              href={`https://${
                location.state.lang != "en" ? location.state.lang + "." : ""
              }dev.appsflyer.com/hc/docs/android-sdk-reference-appsflyerlib`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <code>{t("ip_AppEvents_android_appsFlyerLibLink")}</code>
            </AmplitudeLink>
          </Typography>

          <DJCodeBlock {...codeProps}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("ip_AppEvents_android_stepTwoHeading"),
      component: (
        <>
          <Typography>
            {t("ip_AppEvents_android_stepTwoDescription")}
          </Typography>
          <DJCodeBlock {...codeProps2}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("ip_SendInApp_android_designInApp"),
      component: (
        <>
          <Box>
            <Typography variant="h3">
              {t("ip_SendInApp_android_stepTwoHeading")}
            </Typography>
            <RadioGroup
              row
              value={selectedRadioOne}
              onChange={handleRadioChangeOne}
            >
              <FormControlLabel
                control={<Radio />}
                label={t("ip_SendInApp_android_predefinedLabel")}
                value="Predefined"
              />
              <Select
                id="event-name-1"
                variant="outlined"
                defaultValue={t("ip_SendInApp_ios_chooseOption")}
                placeholder={t("ip_SendInApp_ios_chooseOption")}
                value={chosenEventName}
                onChange={(e) => {
                  setChosenEventName(e);
                  amplitudeTrack_inputChange(
                    location.state,
                    InputType.Select,
                    "eventName",
                    e
                  );
                }}
                disabled={isCustomOne}
                options={options}
                size={"medium"}
              ></Select>
              <FormControlLabel
                sx={{ mx: 2 }}
                control={<Radio />}
                label={t("ip_SendInApp_android_customLabel")}
                value={"custom"}
              />
              <TextField
                variant="outlined"
                id="custom1"
                value={customEventName}
                inputProps={{ className: "amp-unmask" }}
                onChange={(event) => {
                  setCustomEventName(event.target.value);
                  amplitudeTrack_inputChange(
                    location.state,
                    InputType.TextField,
                    "customEventName",
                    event.target.value
                  );
                }}
                disabled={!isCustomOne}
              />
            </RadioGroup>
          </Box>
          <Box>
            <Typography variant="h3">
              {t("ip_SendInApp_android_stepThreeHeading")}
            </Typography>
            {[...Array(paramCount)].map((_, index) => (
              <SendInappEventParamForm
                key={index}
                onEventParamChange={(value) => {
                  switch (index) {
                    case 0:
                      setEventParam1(value);
                      break;
                    case 1:
                      setEventParam2(value);
                      break;
                    case 2:
                      setEventParam3(value);
                      break;
                    case 3:
                      setEventParam4(value);
                      break;
                    case 4:
                      setEventParam5(value);
                      break;
                    default:
                      break;
                  }
                }}
              />
            ))}
            {paramCount < 5 && (
              <Button
                onClick={handleAddParameter}
                fullWidth
                variant="outlined"
                size="large"
                style={{ marginTop: "15px" }}
              >
                {t("ip_SendInApp_ios_addParamButton")}
              </Button>
            )}
          </Box>
          <Box>
            <Typography sx={{ mt: 2 }} variant="h2">
              {t("ip_SendInApp_android_generatedEventCodeHeading")}
            </Typography>

            <DJCodeBlock
              code={generatedEventCode}
              language="java"
              showLineNumbers={true}
            />
          </Box>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("ip_AppEventInApp_android_stepFiveHeading"),
      component: (
        <>
          <Typography>
            {t("ip_AppEventInApp_android_logEventDescription")}
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">
              {t("ip_AppEventInApp_android_responseListenerLabel")}
              <ResponseListenerExplanation />
            </Typography>
            <RadioGroup
              row
              value={responseListRequired}
              onChange={handleResponseChange}
            >
              <FormControlLabel
                label={t("ip_AppEventInApp_android_noLabel")}
                control={<Radio checked={!responseListenerRequired} />}
                onChange={() => setResponseListenerRequired(false)}
                value="No"
              />
              <FormControlLabel
                label={t("ip_AppEventInApp_android_yesLabel")}
                control={<Radio checked={responseListenerRequired} />}
                onChange={() => setResponseListenerRequired(true)}
                value="Yes"
              />
            </RadioGroup>
          </Box>
          <DJCodeBlock
            code={inappCodeWListener}
            language="java"
            showLineNumbers={true}
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography sx={{ mb: 2 }} variant="h1">
          {t("ip_SendInApp_android_sendInAppEventHeading")}
        </Typography>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={
                          activeStep === 2 &&
                          ((!isCustomOne && !chosenEventName) ||
                            (isCustomOne && !customEventName) ||
                            !eventParam1)
                        }
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={activeStep != steps.length - 1}
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(TestSDKIntegrationStepsOneTOThreeAndroid);
