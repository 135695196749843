import { Typography, Tooltip } from "@appsflyer/fe-ui-core";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useLocation } from "react-router-dom";
import { IntegrationPathType, OSType } from "./IProgress";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import AmplitudeLink from "../../services/AmplitudeLink";

const ResponseListenerExplanation = () => {
  const location = useLocation();

  const PREFIX = "HelpOutlineIcon";
  const classes = {
    root: `${PREFIX}-root`,
  };

  const StyledHelpOutlineIcon = styled(HelpOutlineIcon)(({ theme }) => ({
    [`&.${classes.root}`]: {
      color: theme.palette.grey[500],
      "&:hover": {
        color: theme.palette.grey[600],
      },
    },
  }));

  const { t } = useTranslation();

  const TooltipContentStartSDK = () => (
    <div>
      <Typography variant="h3">{t("responseListenerExp_title")}</Typography>
      <Typography variant="body2">
        {t("responseListenerExp_startsdk_text")}
        <AmplitudeLink
          href={
            location.state?.os === OSType.Android
              ? `https://${
                  location.state.lang != "en" ? location.state.lang + "." : ""
                }dev.appsflyer.com/hc/docs/android-sdk-reference-appsflyerrequestlistener`
              : `https://${
                  location.state.lang != "en" ? location.state.lang + "." : ""
                }dev.appsflyer.com/hc/docs/integrate-ios-sdk#start-with-completion-handler`
          }
          target="_blank"
        >
          <code> AppsFlyerRequestListener</code>
        </AmplitudeLink>{" "}
        {t("responseListenerExp_startsdk_text_b")} <code>start</code>:
        <br />
        <ul>
          <li>
            {t("responseListenerExp_startsdk_li1")} <code>onSuccess()</code>{" "}
            {t("responseListenerExp_startsdk_li1_b")}
          </li>
          <li>
            {t("responseListenerExp_startsdk_li2")}{" "}
            <code>onError(String error)</code>{" "}
            {t("responseListenerExp_startsdk_li2_b")}
          </li>
        </ul>
      </Typography>
    </div>
  );

  const TooltipContentInapp = () => (
    <div>
      <Typography variant="h3">{t("responseListenerExp_title")}</Typography>
      <Typography>
        {t("responseListenerExp_inapp_text") + " "}
        <code>logEvent</code>{" "}
        {" " + t("responseListenerExp_inapp_text_b") + " "}
        <AmplitudeLink
          href={
            location.state?.os === OSType.Android
              ? `https://${
                  location.state.lang != "en" ? location.state.lang + "." : ""
                }dev.appsflyer.com/hc/docs/android-sdk-reference-appsflyerrequestlistener`
              : `https://${
                  location.state.lang != "en" ? location.state.lang + "." : ""
                }dev.appsflyer.com/hc/docs/in-app-events-ios#handling-event-submission-success-and-failure`
          }
          target="_blank"
        >
          <code>AppsFlyerRequestListener</code>
        </AmplitudeLink>{" "}
        {t("responseListenerExp_inapp_text_c")}
        <ul>
          <li>{t("responseListenerExp_inapp_li1")}</li>
          <li>{t("responseListenerExp_inapp_li2")}</li>
        </ul>
      </Typography>
    </div>
  );

  return (
    <Tooltip
      placement="top"
      // disableInteractive={false}
      enterTouchDelay={700}
      variant="info"
      title={
        (location.state?.path === IntegrationPathType.InApp && (
          <TooltipContentInapp />
        )) ||
        (location.state?.path === IntegrationPathType.StartSDK && (
          <TooltipContentStartSDK />
        ))
      }
    >
      <span>
        <StyledHelpOutlineIcon
          sx={{ position: "absolute", marginTop: "2px", marginLeft: "2px" }}
          className={classes.root}
          fontSize="small"
          color="action"
        />
      </span>
    </Tooltip>
  );
};

export default ResponseListenerExplanation;
