import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Box,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import DJCodeBlock from "../../main/components/codeblock";
import { useLocation, useNavigate } from "react-router-dom";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
} from "../../services/utils";

const StartSDKiOSGCD = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [gcdRequired, setGCDRequired] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const lsGCDReq = localStorage.getItem(
      `${location.state?.appID}_gcdrequired_android`
    );
    if (lsGCDReq) {
      setGCDRequired(JSON.parse(lsGCDReq));
    }
  }, []);

  const handleClick = () => {
    // Initialize progress with a copy of progressArr
    const progressArr: IProgress[] = location.state?.progress.slice();

    if (progressArr.find((prog) => prog.id === "startsdk_setgcd")) {
      progressArr.find((prog) => prog.id === "startsdk_setgcd").isListener =
        gcdRequired;
    }

    progressArr.push({
      id: "startsdk_startSDK",
      text: "progress_startsdk_startSDK",
      order: 5,
    });

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };

    localStorage.setItem(
      `${progressState.appID}_gcdrequired_android`,
      JSON.stringify(gcdRequired)
    );

    amplitudeTrack_continue(location.state, { gcdRequired });

    navigate(`/startsdk/${location.state?.os.toLowerCase()}/startsdk`, {
      state: progressState,
    });
  };

  const code1 = `extension AppDelegate: AppsFlyerLibDelegate {

}`;
  const code2 = `extension AppDelegate: AppsFlyerLibDelegate {
    // Handle Organic/Non-organic installation
    func onConversionDataSuccess(_ data: [AnyHashable: Any]) {
    // ...
    }
}`;
  const code3 = `extension AppDelegate: AppsFlyerLibDelegate {
     
    // Handle Organic/Non-organic installation
    func onConversionDataSuccess(_ data: [AnyHashable: Any]) {
    // ...
    }
    
    func onConversionDataFail(_ error: Error) {
        NSLog("[AFSDK] \\(error)")
    }
}`;

  const codeBlockArray = [
    { code: code1, language: "swift", showLineNumbers: false },
    { code: code2, language: "swift", showLineNumbers: false },
    { code: code3, language: "swift", showLineNumbers: false },
  ];

  const steps = [
    {
      label: t("startSDK_StartSDK_ios_gcd_step1_title"),
      component: (
        <>
          <Typography mb={1}>
            {t("startSDK_StartSDK_ios_gcd_step1_text")}
            <code>AppDelegate</code>
            {t("startSDK_StartSDK_ios_gcd_step1_text_b")}
            <code>AppsFlyerLibDelegate</code>
            {t("startSDK_StartSDK_ios_gcd_step1_text_c")}
          </Typography>
          <br />

          <DJCodeBlock {...codeBlockArray[0]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("startSDK_StartSDK_ios_gcd_step2_title"),
      component: (
        <>
          <Typography mb={1}>
            {t("startSDK_StartSDK_ios_gcd_step2_text")}
            <code>onConversionDataSuccess</code>
            {t("startSDK_StartSDK_ios_gcd_step2_text_b")}
            <code>NSDictionary</code>
            {t("startSDK_StartSDK_ios_gcd_step2_text_c")}
            <code>data</code>
            {t("startSDK_StartSDK_ios_gcd_step2_text_d")}
            <code>onConversionDataSuccess</code>
            {t("startSDK_StartSDK_ios_gcd_step2_text_e")}
            <b>{t("startSDK_StartSDK_ios_gcd_step2_text_f")}</b>
            {t("startSDK_StartSDK_ios_gcd_step2_text_g")}
          </Typography>
          <br />

          <DJCodeBlock {...codeBlockArray[1]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("startSDK_StartSDK_ios_gcd_step3_title"),
      component: (
        <>
          <Typography mb={1}>
            {t("startSDK_StartSDK_ios_gcd_step3_text")}
            <code>onConversionDataFail</code>
            {t("startSDK_StartSDK_ios_gcd_step3_text_b")}
          </Typography>
          <br />

          <DJCodeBlock {...codeBlockArray[2]}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box>
          <Typography variant="h1">
            {t("startSDK_StartSDK_ios_gcd_title")}
          </Typography>
        </Box>
        <br />
        <Typography variant="body1">
          {t("startSDK_StartSDK_ios_gcd_subtitle") + " "}
          <code>AppsFlyerLibDelegate</code>
          {" " + t("startSDK_StartSDK_ios_gcd_subtitle_b")}
          <br />
          {t("startSDK_StartSDK_ios_gcd_subtitle_c") + " "}
          <code>onConversionDataSuccess</code>
          {" " + t("startSDK_StartSDK_ios_gcd_subtitle_d")}
        </Typography>
        <ul>
          <Typography>
            <li>{t("startSDK_StartSDK_ios_gcd_subtitle_li1")}</li>
          </Typography>
          <Typography>
            <li>{t("startSDK_StartSDK_ios_gcd_subtitle_li2")}</li>
          </Typography>
        </ul>
        <Box display={"flex"} alignItems={"center"} mt={2}>
          <Typography>{t("startSDK_StartSDK_ios_gcd_choose")}</Typography>
          <RadioGroup
            style={{ marginLeft: "50px" }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue="No"
          >
            <FormControlLabel
              onChange={() => {
                setGCDRequired(false);
                amplitudeTrack_inputChange(
                  location.state,
                  InputType.Radio,
                  "gcdRequired",
                  false
                );
              }}
              value="No"
              control={<Radio checked={!gcdRequired} />}
              label={t("startSDK_Step4_android_No")}
            />
            <FormControlLabel
              onChange={() => {
                setGCDRequired(true);
                amplitudeTrack_inputChange(
                  location.state,
                  InputType.Radio,
                  "gcdRequired",
                  true
                );
              }}
              value="Yes"
              control={<Radio checked={gcdRequired} />}
              label={t("startSDK_Step4_android_Yes")}
            />
          </RadioGroup>
        </Box>
        {gcdRequired && (
          <Stepper
            activeStep={activeStep}
            style={{ border: "none" }}
            orientation="vertical"
          >
            {steps.map(
              ({ label, component, shouldDisplayNavigationButtons }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {component}
                    {shouldDisplayNavigationButtons && (
                      <Box sx={{ mt: 2 }}>
                        {activeStep !== 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleBack}
                            // sx={{ mt: 2, mr: 1 }}
                          >
                            {t("general_Back")}
                          </Button>
                        )}
                        {activeStep !== steps.length - 1 && (
                          <Button
                            // sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? t("general_Finish")
                              : t("general_Next")}
                          </Button>
                        )}
                      </Box>
                    )}
                  </StepContent>
                </Step>
              )
            )}
          </Stepper>
        )}
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(StartSDKiOSGCD);
