import { useState, useEffect } from "react";
import { Button, Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock, { ICodeBlock } from "../../main/components/codeblock";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IProgress,
  ISessionProps,
} from "../../main/components/IProgress";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import { amplitudeTrack_continue } from "../../services/utils";

const InstallSDKPermissions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<IProgress[]>([]);

  useEffect(() => {
    const state: ISessionProps = location.state as ISessionProps;
    setProgress(state?.progress);
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    // progressArr.push({
    //   id: "startsdk_gpreferrer",
    //   text: "Google Play referrer",
    //   order: Math.max(...progressArr.map((o) => o.order)) + 1,
    // });
    // Initialize progress with a copy of progressArr
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state)

    navigate(`/startsdk/${location.state.os.toLowerCase()}/referrer`, {
      state: progressState,
    });
  };

  const code = `<manifest xmlns:android="http://schemas.android.com/apk/res/android"
  xmlns:tools="http://schemas.android.com/tools"
  package="${location.state?.appID}">
    <uses-permission android:name="android.permission.INTERNET" />
    <uses-permission android:name="android.permission.ACCESS_NETWORK_STATE" />
    ...
</manifest>`;

  const codeProps: ICodeBlock = {
    code: code,
    language: "swift",
    showLineNumbers: true,
    highlight: "3-5"
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1">
          {t("startSDK_InstallSDK2_android_SettingPermissions")}
        </Typography>
        <Typography variant="h2" fontWeight="bold" mt={5}>
          {t("startSDK_InstallSDK2_android_AddPermissions")}
        </Typography>
        <Typography variant="body1" mb={2}>
          {t("startSDK_InstallSDK2_android_AddToAndroidManifest")}
        </Typography>
        <DJCodeBlock {...codeProps}></DJCodeBlock>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(InstallSDKPermissions);
