import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Alert, Box, Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import {
  Typography,
  TextField,
  ChipSelection,
  LoadingButton,
  SelectMenu,
  Note,
  Collapse,
} from "@appsflyer/fe-ui-core";
import { testConsoleRegister } from "../../services/api";
import {
  IProgress,
  ISessionProps,
  IntegrationPathType,
  OSType,
  SDKTestType,
  SessionStatus,
} from "../components/IProgress";
import transition from "../components/transition";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { DeeplinkType } from "../../deeplinking/DeferredOrDirect";
import {
  amplitudeTrack_changeToManual,
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";
import { v4 as uuidv4 } from "uuid";

const WistiaEmbed = ({ videoId }) => {
  return (
    <div
      className="wistia_embed"
      style={{ position: "relative", height: "360px", width: "640px" }}
    >
      <iframe
        src={`https://fast.wistia.net/embed/iframe/${videoId}`}
        frameBorder="0"
        scrolling="no"
        className="wistia_embed"
        name="wistia_embed"
        allowFullScreen
        width="640"
        height="360"
      />
    </div>
  );
};

const TestConsole_Register = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [deviceId, setDeviceId] = useState("");
  const [storedDeviceId, setStoredDeviceID] = useState("");
  const [deviceType, setDeviceType] = useState(null);
  const [storedDeviceType, setStoredDeviceType] = useState(null);
  const [deviceName, setDeviceName] = useState("");
  const [storedDeviceName, setStoredDeviceName] = useState("");
  const [token, setToken] = useState("");
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
  const [loading, setLoading] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [severity, setSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const gaidRegex =
    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/;
  const zeroGaidRegex =
    /^(?:\{{0,1}(?:[0]){8}-(?:[0]){4}-(?:[0]){4}-(?:[0]){4}-(?:[0]){12}\}{0,1})$/;

  useEffect(() => {
    const lsToken = localStorage.getItem("token");
    if (lsToken) {
      setToken(JSON.parse(lsToken));
    }

    const lsDeviceId = localStorage.getItem(`deviceId_${location.state?.os}`);
    if (lsDeviceId) {
      setDeviceId(JSON.parse(lsDeviceId));
      setStoredDeviceID(JSON.parse(lsDeviceId));
    }

    const lsDeviceType = localStorage.getItem(
      `deviceType_${location.state?.os}`
    );
    if (lsDeviceType) {
      setDeviceType(JSON.parse(lsDeviceType));
      setStoredDeviceType(JSON.parse(lsDeviceType));
    }

    const lsDeviceName = localStorage.getItem(
      `deviceName_${location.state?.os}`
    );
    if (lsDeviceName) {
      setStoredDeviceName(JSON.parse(lsDeviceName));
      setDeviceName(JSON.parse(lsDeviceName));
    }

    const lsIsAlreadyRegistered = localStorage.getItem(
      `isRegistered_${location.state?.os}`
    );
    if (lsIsAlreadyRegistered) {
      setIsAlreadyRegistered(JSON.parse(lsIsAlreadyRegistered));
    }

    if (location.state.os == OSType.Android) {
      setDeviceType({
        label: "GAID (Google Advertising ID)",
        value: "AID",
      });
    } else if (location.state.os == OSType.iOS) {
      setDeviceType({
        label: "IDFA",
        value: "IDFA",
      });
    }
  }, []);

  const registerDevice = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!token) {
      setSeverity("error");
      setIsOpen(true);
      setSnackbarMessage(t("testConsole_TestType_device_missing_token"));
      return;
    }
    if (!deviceType.value) {
      setSeverity("error");
      setIsOpen(true);
      setSnackbarMessage(t("testConsole_TestType_device_missing_type"));
      return;
    }
    if (!deviceName) {
      setSeverity("error");
      setIsOpen(true);
      setSnackbarMessage(t("testConsole_TestType_device_missing_name"));
      return;
    }
    if (!deviceId) {
      setSeverity("error");
      setIsOpen(true);
      setSnackbarMessage(t("testConsole_TestType_device_missing_id"));
      return;
    }

    setLoading(true);
    const progressState: ISessionProps = {
      ...location.state,
    };

    testConsoleRegister(
      progressState.appID,
      deviceId,
      deviceType.value,
      deviceName,
      token
    )
      ?.then((res) => {
        setLoading(false);

        if (res.status === "Error") {
          if (res.data.status === 404 && res.data.error === "Not Found") {
            setSnackbarMessage("AppID not found");
          } else {
            setSnackbarMessage(res.data.error);
          }
          console.error("Error response:", res);
          setSeverity("error");
          setIsOpen(true);
        } else if (res.status === "Success") {
          setSeverity("success");
          setIsOpen(true);
          setSnackbarMessage(
            res.data.message == "ok"
              ? `Device ${deviceType.value} registered successfully`
              : res.data.message
          );
          localStorage.setItem(
            `deviceId_${location.state?.os}`,
            JSON.stringify(deviceId)
          );
          setStoredDeviceID(deviceId);
          localStorage.setItem(
            `deviceType_${location.state?.os}`,
            JSON.stringify(deviceType)
          );
          setStoredDeviceType(deviceType);
          localStorage.setItem(
            `deviceName_${location.state?.os}`,
            JSON.stringify(deviceName)
          );
          setStoredDeviceName(deviceName);
          localStorage.setItem("token", JSON.stringify(token));
          localStorage.setItem(
            `isRegistered_${location.state?.os}`,
            JSON.stringify(true)
          );
          setIsAlreadyRegistered(true);
          // send gtag
          gtag("event", "test_device_register_success", {
            app_name: "devjourney",
            screen_name: "TestConsole_Register",
          });
        } else {
          console.error("Unexpected response:", res);
          setSnackbarMessage("Unexpected response:" + res);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleCloseSnackbar = (
    event: SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  const steps = [
    {
      label: t("testConsole_TestType_token"),
      component: (
        <>
          <TextField
            id="token"
            variant="outlined"
            required
            label={t("testConsole_TestType_token_label") + "*"}
            placeholder={t("testConsole_TestType_token_label") + "*"}
            value={token}
            error={!token}
            type="password"
            onChange={(event) => {
              setToken(event.target.value);
            }}
            size="fullWidth"
            style={{ marginBottom: "5px", maxWidth: "300px" }}
          />
          <Typography>
            <AmplitudeLink
              target="_blank"
              href="https://support.appsflyer.com/hc/en-us/articles/360004562377-Managing-API-tokens#reaching-the-tokens-page"
            >
              {t("testConsole_TestType_token_link")}
            </AmplitudeLink>
          </Typography>
          {!isAlreadyRegistered && <Box mb={4} mt={3}>
            <Note>
              {t("testConsole_TestType_token_notoken")}
              <br />
              <Button sx={{ mt: 1.5 }} color={"primary"} variant="contained" onClick={() => switchToManual()}>
                {t(
                  "testConsole_TestResults_TroubleshootingTips_manual_toManual"
                )}
              </Button>
            </Note>
          </Box>}
          <Box mt={2}>
            <Typography mb={1} variant={"h3"}>
              {t("testConsole_TestType_token_video")}
            </Typography>
            <WistiaEmbed videoId={"6i1wl1g6dw"}></WistiaEmbed>
          </Box>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("testConsole_TestType_myDeviceID"),
      component: (
        <>
          <Typography>
            {t("testConsole_TestType_myDeviceID_text")}
            <AmplitudeLink
              href="https://afdj-myid.onelink.me/lTVA/lg3pxv1k"
              target="_blank"
            >
              {" " + t("testConsole_TestType_myDeviceID_text_b") + " "}
            </AmplitudeLink>
            {t("testConsole_TestType_myDeviceID_text_c")}.
          </Typography>
          <img
            src={
              process.env.PUBLIC_URL + "/images/mydeviceid_lg3pxv1k_qr_code.svg"
            }
            style={{
              margin: "10px 0",
              width: "180px",
              display: "block",
              alignSelf: "flex-start",
            }}
            alt="App is unavailable"
          ></img>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("testConsole_TestType_details"),
      component: (
        <>
          <Box mb={2}>
            <Typography variant="body1" style={{ marginBottom: 15 }}>
              {t("testConsole_TestType_myDeviceID_getId")}
              {location.state?.os === OSType.iOS && (
                <>
                  <br />
                  {t("testConsole_TestType_myDeviceID_iosAtt")}
                </>
              )}
            </Typography>
            <Box
              display={"flex"}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Box minWidth={{ md: 350, xs: 250 }}>
                <Typography variant="body2" style={{ marginLeft: "3px" }}>
                  {t("testConsole_TestType_deviceType")}*
                </Typography>
                <ChipSelection
                  style={{ marginBottom: 10, zIndex: 5 }}
                  chipDefaultValue={t("testConsole_TestType_device_choosetype")}
                  controlType="select"
                  required
                  value={deviceType}
                  onChange={(e) => {
                    setDeviceType(e);
                    amplitudeTrack_inputChange(
                      location.state,
                      InputType.Select,
                      "deviceType",
                      e
                    );
                  }}
                  renderComponent={({
                    value,
                    onChange,
                    isOpen,
                    setIsOpen,
                    anchorRef,
                  }) => (
                    <SelectMenu
                      options={
                        location.state?.os === OSType.Android
                          ? [
                              {
                                label: "GAID (Google Advertising ID)",
                                value: "AID",
                              },
                              // { label: "ANDROID ID", value: "ANDROID ID" },
                            ]
                          : [
                              { label: "IDFA", value: "IDFA" },
                            ]
                      }
                      {...{ value, onChange, isOpen, setIsOpen, anchorRef }}
                    />
                  )}
                />
                <TextField
                  id="devicename"
                  variant="outlined"
                  label={t("testConsole_TestType_device_name") + "*"}
                  value={deviceName}
                  placeholder="My device"
                  inputProps={{ className: "amp-unmask" }}
                  error={!deviceName}
                  required
                  size="fullWidth"
                  style={{ marginBottom: "10px", maxWidth: "300px" }}
                  onChange={(event) => {
                    setDeviceName(event?.target?.value);
                    amplitudeTrack_inputChange(
                      location.state,
                      InputType.TextField,
                      "deviceName",
                      event?.target?.value
                    );
                  }}
                />
                <TextField
                  id="deviceid"
                  variant="outlined"
                  required
                  label={t("testConsole_TestType_device_id") + "*"}
                  value={deviceId}
                  inputProps={{ className: "amp-unmask" }}
                  size="fullWidth"
                  style={{ marginBottom: "5px", maxWidth: "300px" }}
                  error={
                    !gaidRegex.test(deviceId) || zeroGaidRegex.test(deviceId)
                  }
                  placeholder={
                    location.state.os === OSType.Android
                      ? "b2dfa888-26ba-463a-b005-2b4871c42258"
                      : "D02B4900-D31A-509A-B283-6EF0EAE30A53"
                  }
                  onChange={(event) => {
                    setDeviceId(event?.target?.value);
                    amplitudeTrack_inputChange(
                      location.state,
                      InputType.TextField,
                      "deviceId",
                      event?.target?.value
                    );
                  }}
                />
              </Box>
              {deviceType && (
                <Box mt={3}>
                  {deviceType.value == "IDFA" && (
                    <Note>
                      {t("testConsole_TestType_idfa")}
                      <ul style={{ marginLeft: -40 }}>
                        <li>
                          <Typography>
                            {t("testConsole_TestType_idfa_li1_a") + " "}
                            <AmplitudeLink
                              target="_blank"
                              href="https://support.appsflyer.com/hc/en-us/articles/207032066#integration-31-configuring-app-tracking-transparency-att-support"
                            >
                              {t("testConsole_TestType_idfa_li1_b")}
                            </AmplitudeLink>
                            {" " + t("testConsole_TestType_idfa_li1_c")}
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            {t("testConsole_TestType_idfa_li2_a")}
                          </Typography>
                        </li>
                      </ul>
                    </Note>
                  )}
                  {/* {deviceType.value == "IDFV" && (
                    <Note>
                      <Typography>
                        {t("testConsole_TestType_idfv_a") + " "}
                        <AmplitudeLink
                          target="_blank"
                          href={`https://${
                            location.state.lang != "en"
                              ? location.state.lang + "."
                              : ""
                          }dev.appsflyer.com/hc/docs/install-the-idfv-test-tool-app`}
                        >
                          {t("testConsole_TestType_idfv_b")}
                        </AmplitudeLink>
                      </Typography>
                    </Note>
                  )} */}
                </Box>
              )}
            </Box>
            {zeroGaidRegex.test(deviceId) && (
              <Box mt={2} mb={3}>
                <Note>
                  {t("testConsole_TestType_simulatorid")}
                  <br />(<code>00000000-0000-0000-0000-000000000000</code>)
                </Note>
              </Box>
            )}
          </Box>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            size="medium"
            onClick={(e) => registerDevice(e)}
            disabled={
              loading ||
              !deviceType ||
              !token ||
              !deviceId ||
              !deviceName ||
              !gaidRegex.test(deviceId) ||
              zeroGaidRegex.test(deviceId)
            }
            loading={loading}
            style={{ marginRight: "5px", zIndex: 4 }}
            aria-label={t("testConsole_TestType_device_register")}
          >
            {t("testConsole_TestType_device_register")}
          </LoadingButton>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const handleClick = () => {
    // Initialize progress with a copy of progressArr
    const progressArr: IProgress[] = location.state?.progress.slice();

    if (location.state?.path === IntegrationPathType.StartSDK) {
      progressArr.push({
        id: "startsdk_testintegration",
        text: "progress_startsdk_testintegration",
        order: 7,
      });
    } else if (location.state?.path === IntegrationPathType.InApp) {
      progressArr.push({
        id: "inapp_testintegration",
        text: "progress_inapp_testintegration",
        order: 4,
      });
    } else if (location.state?.path === IntegrationPathType.DeepLink) {
      if (location.state?.deeplinkType == DeeplinkType.Deferred) {
        if (!progressArr.some((prog) => prog.id === "dl_testDeferred")) {
          progressArr.push({
            id: "dl_testDeferred",
            text: "progress_dl_testDeferred",
            order: 6,
          });
        }
      } else {
        if (!progressArr.some((prog) => prog.id === "dl_testDirect")) {
          progressArr.push({
            id: "dl_testDirect",
            text: "progress_dl_testDirect",
            order: 7,
          });
        }
      }
    }

    const progressState: ISessionProps = {
      ...location.state,
      token,
      deviceId,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
      deviceType: storedDeviceType?.label,
    };

    amplitudeTrack_continue(location.state);

    if (location.state?.path === IntegrationPathType.StartSDK) {
      navigate(`/startsdk/${progressState.os}/testsdkintegration`, {
        state: progressState,
      });
    } else if (location.state?.path === IntegrationPathType.InApp) {
      navigate(`/inapp/${progressState.os}/testinappintegration`, {
        state: progressState,
      });
    } else if (location.state?.path === IntegrationPathType.DeepLink) {
      navigate(`/deeplink/${progressState.os}/testdeferreddl`, {
        state: progressState,
      });
    }
  };

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const ariaLabelPrefix = isCollapseOpen ? "Show Less" : "Show More";

  const switchToManual = () => {
    const newStateArr: IProgress[] = (
      location.state.progress as IProgress[]
    ).filter(x => x.order < (location.state.os == OSType.Android ? 6 : 5));
    newStateArr.push({
      id: "startsdk_testintegration_manual",
      text: "progress_startsdk_testintegration_manual",
      order: 7,
    });

    const progressState: ISessionProps = {
      ...location.state,
      progress: newStateArr,
      sdkTestType: SDKTestType.Manual,
      sessionStatus: SessionStatus.IncompleteTesting,
      currentPage: location.state?.currentPage - 2,
      startTime: new Date(),
      updateTime: new Date(),
      sessionId: uuidv4(),
    };

    amplitudeTrack_continue(progressState, { testType: SDKTestType.Manual });
    amplitudeTrack_changeToManual(progressState)

    navigate(`/startsdk/${progressState.os}/testsdkintegration`, {
      state: progressState,
    });
  }

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        {location.state?.path === IntegrationPathType.StartSDK && (
          <Typography variant="h1">
            {t("startSDK_Step5_android_TestSDKIntegration")}
          </Typography>
        )}
        {location.state?.path === IntegrationPathType.InApp && (
          <Typography variant="h1">
            {t("ip_TestInAppIntegration_android_testSDKIntegrationHeading")}
          </Typography>
        )}
        <Typography variant="h2">
          {t("testConsole_TestType_register")}
        </Typography>
        <Typography variant="body1">
          {t("testConsole_TestType_already")}
        </Typography>
        {isAlreadyRegistered ? (
          <>
            <Box mt={3} mb={3}>
              <Note>
                <Typography style={{ dispay: "inline" }}>
                  {t("testConsole_register_alreadyRegisteredDevice")}
                </Typography>
                <hr />
                <Typography>
                  <b>{t("testConsole_register_deviceName")}</b>{" "}
                  {storedDeviceName}
                </Typography>
                <Typography>
                  <b>{t("testConsole_register_deviceType")}</b>{" "}
                  {storedDeviceType?.label}
                </Typography>
                <Typography>
                  <b>{t("testConsole_register_deviceId")}</b> {storedDeviceId}
                </Typography>
              </Note>
            </Box>
            <Collapse
              buttonVariant="text"
              isOpen={isCollapseOpen}
              onClick={() => setIsCollapseOpen((o) => !o)}
              title={t("testConsole_TestType_register_replace")}
              ButtonProps={{
                IconProps: {
                  "aria-label": `${ariaLabelPrefix} Top Expand Icon`,
                },
              }}
            >
              <Stepper
                activeStep={activeStep}
                style={{ border: "none" }}
                orientation="vertical"
              >
                {steps.map(
                  ({ label, component, shouldDisplayNavigationButtons }) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        {component}
                        {shouldDisplayNavigationButtons && (
                          <Box sx={{ mt: 2 }}>
                            {activeStep !== 0 && (
                              <Button
                                variant="text"
                                color="primary"
                                onClick={handleBack}
                                // sx={{ mt: 2, mr: 1 }}
                              >
                                {t("general_Back")}
                              </Button>
                            )}
                            {activeStep !== steps.length - 1 && (
                              <Button
                                // sx={{ mt: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                disabled={activeStep === 0 && !token}
                              >
                                {activeStep === steps.length - 1
                                  ? t("general_Finish")
                                  : t("general_Next")}
                              </Button>
                            )}
                          </Box>
                        )}
                      </StepContent>
                    </Step>
                  )
                )}
              </Stepper>
            </Collapse>
          </>
        ) : (
          <Stepper
            activeStep={activeStep}
            style={{ border: "none" }}
            orientation="vertical"
          >
            {steps.map(
              ({ label, component, shouldDisplayNavigationButtons }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {component}
                    {shouldDisplayNavigationButtons && (
                      <Box sx={{ mt: 2 }}>
                        {activeStep !== 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleBack}
                            // sx={{ mt: 2, mr: 1 }}
                          >
                            {t("general_Back")}
                          </Button>
                        )}
                        {activeStep !== steps.length - 1 && (
                          <Button
                            // sx={{ mt: 2 }}
                            disabled={activeStep === 0 && !token}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? t("general_Finish")
                              : t("general_Next")}
                          </Button>
                        )}
                      </Box>
                    )}
                  </StepContent>
                </Step>
              )
            )}
          </Stepper>
        )}
        <Snackbar
          open={isOpen}
          autoHideDuration={100000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          style={{ position: "sticky", marginTop: 20, zIndex: 0 }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={severity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          style={{ marginRight: "5px" }}
          onClick={() => handleClick()}
          disabled={!token || !deviceId || !isAlreadyRegistered}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(TestConsole_Register);

