import { Button, Box, Container, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../components/transition";
import {
  ISessionProps,
  IntegrationPathType,
  OSType,
  SessionStatus,
} from "../components/IProgress";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

const Finish = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setPathDone(
      location.state?.appID,
      location.state?.os,
      location.state?.path,
      location.state?.completionStatus
    );

    const progressState: ISessionProps = {
      currentPage: 2,
      appID: location.state?.appID,
      os: location.state?.os,
      lang: location.state?.lang,
      progress: location.state?.progress.slice(0, 1),
      startTime: new Date(),
      updateTime: new Date(),
      sessionId: uuidv4(),
      sessionStatus: SessionStatus.Incomplete,
    };
    navigate(`/integrationPath`, { state: progressState });
  };

  const setPathDone = (
    appId: string,
    os: OSType,
    path: IntegrationPathType,
    completionStatus: boolean
  ) => {
    if (completionStatus) {
      // if test passed, save it as successful
      localStorage.setItem(
        `isComplete_${appId}_${os}_${path}`,
        JSON.stringify(completionStatus)
      );
    }
  };

  const { t } = useTranslation();

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box
          textAlign={"center"}
          marginTop={10}
          padding={5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Typography variant="h1" sx={{ mb: 1 }}>
            {t("general_journey_cong")}
          </Typography>

          {/* picture */}
          <img
            style={{ width: "300px", display: "flex", alignSelf: "center" }}
            src={process.env.PUBLIC_URL + "/images/winners.svg"}
            alt="AppsFlyer DevJourney logo"
          />

          <Box my={4}>
            <Typography variant="h2">
              {t("general_journey_anotherj")}
            </Typography>
            <Box
              display={"flex"}
              marginTop={2}
              gap={1}
              justifyContent={"center"}
            >
              <Tooltip title={t("general_journey_anotherf")} arrow>
                <Button
                  variant="contained"
                  color="primary"
                  // sx={{ p: 3 }}
                  onClick={() => handleClick()}
                >
                  <Typography>{t("general_journey_anotherf")}</Typography>
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box marginTop={10}></Box>
    </Container>
  );
};

export default transition(Finish);
