import { useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Box,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IProgress,
  ISessionProps,
  SessionStatus,
} from "../../main/components/IProgress";
import DJCodeBlock, { ICodeBlock } from "../../main/components/codeblock";
import { Typography } from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import transition from "../../main/components/transition";
import ResponseListenerExplanation from "../../main/components/ResponseListenerExp";
import { amplitudeTrack_continue, amplitudeTrack_inputChange, InputType, sendGtagOnce } from "../../services/utils";

const StartSDKiOS = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [devKey, setDevKey] = useState();
  const [appId, setAppId] = useState<string>("");
  const [gcdRequired, setGCDRequired] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setAppId(state?.appID ?? "");

      const lsDevKey = localStorage.getItem("devKey");
      if (lsDevKey) {
        setDevKey(JSON.parse(lsDevKey));
      }

      if (
        location.state?.progress.find((prog) => prog.id === "startsdk_setgcd")
      ) {
        setGCDRequired(
          location.state?.progress.find((prog) => prog.id === "startsdk_setgcd")
            .isListener
        );
      }
    }
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = location.state?.progress.slice();
    progressArr[progressArr.length - 1].isListener = gcdRequired;

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      sessionStatus: SessionStatus.ImplementedNotTested,
      currentPage: location.state?.currentPage + 1,
    };

    // send gtag for implemented not test
    sendGtagOnce(
      "implemented_not_tested",
      "StartSDKiOS",
      location.state.sessionId
    );

    amplitudeTrack_continue(location.state, { responseListenerRequired: responseListener });

    navigate(`/startsdk/${location.state?.os}/testsdktype`, {
      state: progressState,
    });
  };

  const code = `import UIKit
import AppsFlyerLib`;

  const code2 = `@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {
    // ...
    func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool { 
        // ...
        AppsFlyerLib.shared().appsFlyerDevKey = "${devKey}"
        AppsFlyerLib.shared().appleAppID = "${appId}" ${
    gcdRequired
      ? `
        AppsFlyerLib.shared().delegate = self`
      : ""
  }
        // ...         
    }
}`;

  const code3 = `AppsFlyerLib.shared().isDebug = true`;
  const codeProps: ICodeBlock = {
    code: code,
    language: "swift",
    showLineNumbers: true,
  };
  const codeProps2: ICodeBlock = {
    code: code2,
    language: "swift",
    showLineNumbers: true,
  };
  const codeProps3: ICodeBlock = {
    code: code3,
    language: "swift",
    showLineNumbers: true,
  };

  const [responseListener, setResponseListener] = useState("no-response");
  const [result, setResult] = useState("no-response");

  const codePropsNoDelegateNoResponse: ICodeBlock = {
    code: `@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {     
    // ...
    
    func applicationDidBecomeActive(_ application: UIApplication) {
        // ...
        AppsFlyerLib.shared().start()
    
        // ...
    }
    
    // ...
}`,
    language: "swift",
    showLineNumbers: true,
  };

  const codePropsNoDelegateYesResponse: ICodeBlock = {
    code: `@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {
// ...
func applicationDidBecomeActive(_ application: UIApplication) {
  // ...
  AppsFlyerLib.shared()?.start(completionHandler: { (dictionary, error) in
        if (error != nil){
            print(error ?? "")
            return
        } else {
            print(dictionary ?? "")
            return
        }
    })
    // ...
  }
  // ...
}`,
    language: "swift",
    showLineNumbers: true,
  };

  const handleResponseListenerChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setResponseListener(value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "responseListenerRequired",
      event.target.value
    );
    updateResult(value);
  };

  const updateResult = (responseListenerValue: string) => {
    const newResult = `${responseListenerValue}`;
    setResult(newResult);
  };

  const code_step5 = `class AppDelegate: UIResponder, UIApplicationDelegate {
  // ...
  func application(
    _ application: UIApplication,
    didFinishLaunchingWithOptions launchOptions:
      [UIApplication.LaunchOptionsKey: Any]?
  ) -> Bool {
    AppsFlyerLib.shared().waitForATTUserAuthorization(timeoutInterval: 60)
    // Subscribe to didBecomeActiveNotification if you use SceneDelegate
    NotificationCenter.default.addObserver(
      self,
      selector:
        #selector(didBecomeActiveNotification),
      name: UIApplication.didBecomeActiveNotification,
      object: nil)
    // ...
  }
  @objc func didBecomeActiveNotification() {
    AppsFlyerLib.shared().start()
    if #available(iOS 14, *) {
      ATTrackingManager.requestTrackingAuthorization { (status) in
        switch status {
        case .denied:
          print("AuthorizationSatus is denied")
        case .notDetermined:
          print("AuthorizationSatus is notDetermined")
        case .restricted:
          print("AuthorizationSatus is restricted")
        case .authorized:
          print("AuthorizationSatus is authorized")
        @unknown default:
          fatalError("Invalid authorization status")
        }
      }
    }
  }
}`;
  const codeProps_step5: ICodeBlock = {
    code: code_step5,
    language: "swift",
    showLineNumbers: true,
  };

  const steps = [
    {
      label: t("StartSDK_startSDK_ios_step1"),
      component: (
        <>
          <DJCodeBlock {...codeProps}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_startSDK_ios_step2"),
      component: (
        <>
          <DJCodeBlock {...codeProps2}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_startSDK_ios_step3"),
      component: (
        <>
          <DJCodeBlock {...codeProps3}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_startSDKStep4_ios_step4"),
      component: (
        <>
          <Box display={"flex"} gap={5} mt={1} mb={1}>
            <Box>
              <Typography>
                {t("StartSDK_startSDKStep4_ios_response")}
                <ResponseListenerExplanation />
              </Typography>
              <RadioGroup
                aria-labelledby="response-listener-group-label"
                value={responseListener}
                onChange={handleResponseListenerChange}
                name="response-listener-group"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="no-response"
                  control={<Radio />}
                  label={t("ip_AppEventStepFiveIos_ios_noLabel")}
                />
                <FormControlLabel
                  value="yes-response"
                  control={<Radio />}
                  label={t("ip_AppEventInApp_android_yesLabel")}
                />
              </RadioGroup>
            </Box>
          </Box>

          {result === "no-response" && (
            <DJCodeBlock {...codePropsNoDelegateNoResponse} />
          )}
          {result === "yes-response" && (
            <DJCodeBlock {...codePropsNoDelegateYesResponse} />
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_startSDKStep5_ios_step5"),
      component: (
        <>
          <DJCodeBlock {...codeProps_step5}></DJCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1">{t("start_sdk")}</Typography>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(StartSDKiOS);
