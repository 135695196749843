import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Home from "./Components/main/pages/Homepage";

// Android:
import SendInAppEventStepsOneAndTwoAndroid from "./Components/inapp/android/Sendinapp";
import TestSDKIntegration from "./Components/startsdk/TestSDKIntegration";

// Ios:
//page5
import SendInAppEventStepsOneAndTwoIos from "./Components/inapp/ios/Sendinapp";
//page7
//page8
import InApp_TestSDKIntegration from "./Components/inapp/TestInappIntegration";
//page9

import { Route, Routes, BrowserRouter } from "react-router-dom";

import DL_prepareURI from "./Components/deeplinking/ios/DL_prepareURI";
import DL_prepareUniversalLinks from "./Components/deeplinking/ios/DL_prepareUniversalLinks";
import DL_implementCodeIOS from "./Components/deeplinking/ios/DL_implementCodeIOS";
import DL_testDeferredIOS from "./Components/deeplinking/ios/DL_testDeferredIOS";
import TestDeferredDL from "./Components/deeplinking/TestDeferredDL";
import DL_testDirectManual from "./Components/deeplinking/DL_testDirectManual";

import DL_prepareAndroid from "./Components/deeplinking/android/DL_prepare1Android";
import DL_prepare2Android from "./Components/deeplinking/android/DL_prepare2Android";
import DL_implementAndroid from "./Components/deeplinking/android/DL_implementAndroid";
import TestDeferredAndroid from "./Components/deeplinking/android/DL_TestDeferredAndroid";

import AppParameter from "./Components/main/pages/AppParameters";
import InstallSDK from "./Components/startsdk/ios/installSDK";
import InstallSDKandroid from "./Components/startsdk/android/InstallSDK";
import StartSDKiOS from "./Components/startsdk/ios/StartSDKiOS";
import StartSDKAndroid from "./Components/startsdk/android/StartSDKAndroid";
import ReferrerPage from "./Components/startsdk/android/ReferrerPage";
import InstallSDKPermissions from "./Components/startsdk/android/InstallSDKPermissions";
import TestSDKUID from "./Components/startsdk/TestSDKUID";
import TestResultPassed from "./Components/startsdk/TestResultPassed";
import Layout from "./Components/main/components/Layout";

import IntegrationPath from "./Components/main/pages/integrationPath";
import ChooseOsPath from "./Components/main/pages/ChooseOsPath";
import DeferredOrDirectAndroid from "./Components/deeplinking/DeferredOrDirect";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@appsflyer/fe-ui-theme";
import theme from "./theme";
import "./App.css";
import Finish from "./Components/main/pages/Finish";
import TestingTypePath from "./Components/main/pages/TestingTypePath";
import TestConsole_Register from "./Components/main/pages/TestConsole_Register";
import TestConsole_Results from "./Components/main/pages/TestConsole_Results";
import DL_prepareUniversalLinks2 from "./Components/deeplinking/ios/DL_prepareUniversalLinks2";
import DL_prepare3Android from "./Components/deeplinking/android/DL_prepare3Android";
import StartSDKAndroidGCD from "./Components/startsdk/android/StartSDKAndroidGCD";
import StartSDKiOSGCD from "./Components/startsdk/ios/StartSDKiOSGCD";
import { useIdleTimer } from "react-idle-timer";
import NotFound from "./Components/main/pages/NotFound";

import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

export default function App() {
  const [isPaused, setIsPaused] = useState(false);
  const [timeCounter, setTimeCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setTimeCounter(timeCounter + 1);
        // console.log("timeCounter", timeCounter);
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    // amplitude.setUserId('dj@appsflyer.com');

    // Create and Install Session Replay Plugin
    const sessionReplayTracking = sessionReplayPlugin();
    amplitude.add(sessionReplayTracking);

    // Your existing initialization logic with Browser SDK
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
      defaultTracking: {
        sessions: true,
        formInteractions: true
      },
    });
  }, []);

  const onIdle = () => {
    // console.log("User is idle", event);
    setIsPaused(true);
  };

  const onActive = () => {
    // console.log("User is active", event);
    setIsPaused(false);
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 60_000,
    throttle: 500,
  });

  // const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <BrowserRouter>
            <div>
              <Routes>
                <Route element={<Layout sessionTime={timeCounter} />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/finish" element={<Finish />} />
                  <Route path="/chooseospath" element={<ChooseOsPath />} />
                  <Route path="/appparams" element={<AppParameter />} />
                  <Route
                    path="/integrationpath"
                    element={<IntegrationPath />}
                  />
                  {/* <Route path="/test" element={<Test />} /> */}

                  {/* startSDK */}

                  <Route
                    path="/startsdk/android/testresults"
                    element={<TestResultPassed />}
                  />
                  <Route
                    path="/startsdk/ios/testresults"
                    element={<TestResultPassed />}
                  />

                  {/* ios path */}
                  <Route
                    path="/startsdk/ios/installsdk"
                    element={<InstallSDK />}
                  />
                  <Route
                    path="/startsdk/ios/setGCD"
                    element={<StartSDKiOSGCD />}
                  />
                  <Route
                    path="/startsdk/ios/startsdk"
                    element={<StartSDKiOS />}
                  />
                  <Route
                    path="/startsdk/ios/testsdkintegration"
                    element={<TestSDKIntegration />}
                  />
                  <Route
                    path="/startsdk/ios/testsdktype"
                    element={<TestingTypePath />}
                  />
                  <Route
                    path="/startsdk/ios/testconsole"
                    element={<TestConsole_Register />}
                  />
                  <Route
                    path="/startsdk/ios/eventstestconsole"
                    element={<TestConsole_Results />}
                  />
                  <Route
                    path="/startsdk/ios/testsdkuid"
                    element={<TestSDKUID />}
                  />

                  {/* android path */}
                  <Route
                    path="/startsdk/android/installsdk"
                    element={<InstallSDKandroid />}
                  />
                  <Route
                    path="/startsdk/android/setpermissions"
                    element={<InstallSDKPermissions />}
                  />
                  <Route
                    path="/startsdk/android/startsdk"
                    element={<StartSDKAndroid />}
                  />
                  <Route
                    path="/startsdk/android/setGCD"
                    element={<StartSDKAndroidGCD />}
                  />
                  <Route
                    path="/startsdk/android/referrer"
                    element={<ReferrerPage />}
                  />
                  <Route
                    path="/startsdk/android/testsdkintegration"
                    element={<TestSDKIntegration />}
                  />
                  <Route
                    path="/startsdk/android/testsdktype"
                    element={<TestingTypePath />}
                  />
                  <Route
                    path="/startsdk/android/testconsole"
                    element={<TestConsole_Register />}
                  />
                  <Route
                    path="/startsdk/android/eventstestconsole"
                    element={<TestConsole_Results />}
                  />
                  <Route
                    path="/startsdk/android/testsdkuid"
                    element={<TestSDKUID />}
                  />
                  <Route
                    path="/deeplink/android/prepare"
                    element={<DL_prepareAndroid />}
                  />
                  <Route
                    path="/deeplink/android/prepare2"
                    element={<DL_prepare2Android />}
                  />
                  <Route
                    path="/deeplink/android/prepare3"
                    element={<DL_prepare3Android />}
                  />
                  <Route
                    path="/deeplink/android/implement"
                    element={<DL_implementAndroid />}
                  />
                  <Route
                    path="/deeplink/android/testdeferred"
                    element={<TestDeferredAndroid />}
                  />
                  <Route
                    path="/deeplink/android/testdeferreddl"
                    element={<TestDeferredDL />}
                  />
                  <Route
                    path="/deeplink/android/deeplinktype"
                    element={<DeferredOrDirectAndroid />}
                  />
                  <Route
                    path="/deeplink/android/testDirect"
                    element={<DL_testDirectManual />}
                  />
                  <Route
                    path="/deeplink/android/testtype"
                    element={<TestingTypePath />}
                  />
                  <Route
                    path="/deeplink/android/testconsole"
                    element={<TestConsole_Register />}
                  />
                  <Route
                    path="/deeplink/android/eventstestconsole"
                    element={<TestConsole_Results />}
                  />
                  <Route
                    path="/deeplink/ios/prepare"
                    element={<DL_prepareUniversalLinks />}
                  />
                  <Route
                    path="/deeplink/ios/prepare2"
                    element={<DL_prepareUniversalLinks2 />}
                  />
                  <Route
                    path="/deeplink/ios/prepareURI"
                    element={<DL_prepareURI />}
                  />
                  <Route
                    path="/deeplink/ios/implement"
                    element={<DL_implementCodeIOS />}
                  />
                  <Route
                    path="/deeplink/ios/testdeferred"
                    element={<DL_testDeferredIOS />}
                  />
                  <Route
                    path="/deeplink/ios/testdeferreddl"
                    element={<TestDeferredDL />}
                  />
                  <Route
                    path="/deeplink/ios/testDirect"
                    element={<DL_testDirectManual />}
                  />
                  <Route
                    path="/deeplink/ios/deeplinktype"
                    element={<DeferredOrDirectAndroid />}
                  />
                  <Route
                    path="/deeplink/ios/testtype"
                    element={<TestingTypePath />}
                  />
                  <Route
                    path="/deeplink/ios/testconsole"
                    element={<TestConsole_Register />}
                  />
                  <Route
                    path="/deeplink/ios/eventstestconsole"
                    element={<TestConsole_Results />}
                  />

                  <Route path="/home" element={<Home />} />
                  {/* <Route path="/test" element={<TestComponent />} /> */}
                  {/* Apple */}
                  <Route
                    path="/inapp/ios/sendinapp"
                    element={<SendInAppEventStepsOneAndTwoIos />}
                  />
                  <Route
                    path="/inapp/ios/testinappintegration"
                    element={<InApp_TestSDKIntegration />}
                  />

                  <Route
                    path="/inapp/ios/testtype"
                    element={<TestingTypePath />}
                  />

                  <Route
                    path="/inapp/ios/testconsole"
                    element={<TestConsole_Register />}
                  />
                  <Route
                    path="/inapp/ios/eventstestconsole"
                    element={<TestConsole_Results />}
                  />

                  <Route
                    path="/inapp/android/testtype"
                    element={<TestingTypePath />}
                  />

                  {/* Android */}
                  <Route
                    path="/inapp/android/sendinapp"
                    element={<SendInAppEventStepsOneAndTwoAndroid />}
                  />
                  <Route
                    path="/inapp/android/testinappintegration"
                    element={<InApp_TestSDKIntegration />}
                  />
                  <Route
                    path="/inapp/android/testconsole"
                    element={<TestConsole_Register />}
                  />
                  <Route
                    path="/inapp/android/eventstestconsole"
                    element={<TestConsole_Results />}
                  />
                  <Route path="/notfound" element={<NotFound />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </div>
          </BrowserRouter>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
