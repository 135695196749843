import { Typography, Tooltip } from "@appsflyer/fe-ui-core";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useLocation } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock, { ICodeBlock } from "./codeblock";
import { OSType } from "./IProgress";

const StartFunctionExplanation = () => {
  const location = useLocation();

  const PREFIX = "HelpOutlineIcon";
  const classes = {
    root: `${PREFIX}-root`,
  };

  const StyledHelpOutlineIcon = styled(HelpOutlineIcon)(({ theme }) => ({
    [`&.${classes.root}`]: {
      color: theme.palette.grey[500],
      "&:hover": {
        color: theme.palette.grey[600],
      },
    },
  }));

  const codeblockPropsAndroid: ICodeBlock = {
    code: `AppsFlyerLib.getInstance().start(this);`,
    language: "java",
    showLineNumbers: true,
  };

  const codeblockPropsIOS: ICodeBlock = {
    code: `AppsFlyerLib.shared().start()`,
    language: "swift",
    showLineNumbers: true,
  };

  const { t } = useTranslation();

  const TooltipContentStartSDK = () => (
    <Box minWidth={300}>
      <Typography variant="h3">{t("StartSDK_startSDKStep4_ios_step4")}</Typography>
      <>
        <Typography>{t("testConsole_TestResults_TroubleshootingTips_startFunc")}</Typography>

        <Box mt={2} minWidth={200}>
          {location.state.os === OSType.Android ? (
            <DJCodeBlock {...codeblockPropsAndroid}></DJCodeBlock>
          ) : (
            <DJCodeBlock {...codeblockPropsIOS}></DJCodeBlock>
          )}
        </Box>
      </>
    </Box>
  );

  return (
    <Tooltip
      placement="top"
      enterTouchDelay={700}
      variant="info"
      title={<TooltipContentStartSDK />}
    >
      <span>
        <StyledHelpOutlineIcon
          sx={{ position: "absolute", marginTop: "2px", marginLeft: "2px" }}
          className={classes.root}
          fontSize="small"
          color="action"
        />
      </span>
      <div style={{ width: 10, height: 100, display: "inline" }}></div>
    </Tooltip>
  );
};

export default StartFunctionExplanation;
