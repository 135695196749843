import { Link } from "@mui/material";
import { LinkProps } from '@mui/material/Link/Link'
import { HTMLAttributes } from "react";
import { useLocation } from "react-router-dom";
import { amplitudeTrack_linkClick } from "./utils";

type NavLinkProps = LinkProps & HTMLAttributes<HTMLAnchorElement>

interface AmplitudeLinkProps extends NavLinkProps {
  href: string;
}

const AmplitudeLink = ({ children, ...props }: AmplitudeLinkProps) => {
  const location = useLocation();

  return (
    <Link
      href={props.href}
      {...props}
      onClick={() => amplitudeTrack_linkClick(location.state, props.href)}
    >
      {children}
    </Link>
  );
};

export default AmplitudeLink;
