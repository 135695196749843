import { useState, ChangeEvent, useEffect } from "react";
import {
  Button,
  Box,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DJCodeBlock, { ICodeBlock } from "../../main/components/codeblock";
import { useLocation, useNavigate } from "react-router-dom";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Typography } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import { amplitudeTrack_continue, amplitudeTrack_inputChange, InputType } from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

const code = `pod 'AppsFlyerFramework'`;
const code2 = `pod 'AppsFlyerFramework/Strict`;

const codeProps: ICodeBlock = {
  code: code,
  language: "swift",
  showLineNumbers: true,
};
const codeProps2: ICodeBlock = {
  code: code2,
  language: "swift",
  showLineNumbers: true,
};

const InstallSDK = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setProgress(state?.progress);
    }
  }, [location.state]);

  const handleClick = () => {
    const progressArr: IProgress[] = progress.slice();
    progressArr.push({
      id: "startsdk_setgcd",
      text: "progress_startsdk_setgcd",
      order: 3,
    });
    // Initialize progress with a copy of progressArr
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state);

    navigate(`/startSDK/${location.state?.os.toLowerCase()}/setGCD`, {
      state: progressState,
    });
  };
  const { t } = useTranslation();
  const [value, setValue] = useState(t("StartSDK_installSDK_ios_regularmode"));

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "strictOrRegularMode",
      event.target.value
    );
  };

  const steps = [
    {
      label: t("StartSDK_installSDK_ios_step1"),
      component: (
        <>
          <Typography>{t("StartSDK_installSDK_ios_download")}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK_ios_step2"),
      component: (
        <>
          <Typography>{t("StartSDK_installSDK_ios_Add")}</Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={value}
            onChange={handleChange}
            name="radio-buttons-group"
            sx={{ mt: 2 }}
          >
            <FormControlLabel
              value={t("StartSDK_installSDK_ios_regularmode")}
              control={<Radio />}
              label={t("StartSDK_installSDK_ios_regularmode")}
            />
            <FormControlLabel
              value={t("StartSDK_installSDK_ios_strictmode")}
              control={<Radio />}
              label={t("StartSDK_installSDK_ios_strictmode")}
            />
          </RadioGroup>
          {value === t("StartSDK_installSDK_ios_regularmode") ? (
            <Box my={3}>
              <DJCodeBlock {...codeProps}></DJCodeBlock>
            </Box>
          ) : (
            <Box my={3}>
              <DJCodeBlock {...codeProps2}></DJCodeBlock>
            </Box>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK2_ios_step3"),
      component: (
        <>
          <Typography>
            {t("StartSDK_installSDK2_ios_add")}
            <AmplitudeLink
              target="_blank"
              href={"https://cocoapods.org/pods/AppsFlyerFramework"}
            >
              {t("StartSDK_installSDK2_ios_addLatest")}
            </AmplitudeLink>{" "}
            {t("StartSDK_installSDK2_ios_add2")}
          </Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("StartSDK_installSDK2_ios_step4"),
      component: (
        <>
          <Typography>
            {t("StartSDK_installSDK2_ios_xcode")}
            <code> .xcworkspace </code>
            {t("StartSDK_installSDK2_ios_xcode_b")}
            <code> .xcworkspace </code>
            {t("StartSDK_installSDK2_ios_xcode_c")}
            <code> .xcodeproj </code>
            {t("StartSDK_installSDK2_ios_xcode_d")}
          </Typography>
          <Typography mt={2}>{t("StartSDK_installSDK2_ios_ifyou")}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Typography variant="h1">
          {t("StartSDK_stepsPage_ios_installSDK")}
        </Typography>
        <Typography variant="h3">
          {t("dl_15_ios_deep_link_link_use") + " "}
          <AmplitudeLink
            target="_blank"
            href={`https://${
              location.state.lang != "en" ? location.state.lang + "." : ""
            }dev.appsflyer.com/hc/docs/install-ios-sdk#install-using-cocoapods`}
          >
            {t("StartSDK_installSDK_ios_installCocoa")}
          </AmplitudeLink>
        </Typography>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(InstallSDK);
